//@ts-nocheck
import React from "react";
import ValueRoadmapController, { Props } from "./ValueRoadmapController.web";
import {
  Typography,
  Button,
  Grid,
  Box,
  withStyles,
  createStyles,
  Divider,
  TextField,
  InputAdornment,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  TableContainer,
  Table,
  Slider,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core";
import { formatCurrency } from "../CustomPackage/CustomPackageCreationSelectFeaturesPage.web";
import Pdf from "../../../../components/src/reactPdf.js";
import addImg from "../../assets/0937e2df00dec93b5a6feecbdcc621dc27832c38.png";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import moment from "moment";
import { CheckOutlined, CloseOutlined } from "@material-ui/icons";
import withPermissions from "../../../../components/src/withPermissions.web";
import CustomGrid from "../../../../components/src/CustomGrid.web";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import RoadMapUIWeb, { roadmapStyles } from "../roadmap/RoadMapUI.web";
import html2pdf from 'html2pdf.js'
import ViewDocumentDialogWeb from "../Settings/ViewDocumentDialog.web"

//
const PrettoSlider = withStyles((theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      height: 8,
      width: "75%",
      padding: 0,
    },
    thumb: {
      height: 0,
      width: 0,
      backgroundColor: "#fff",
      border: "none",
      marginTop: -8,
      marginLeft: -24,
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit",
      },
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 4px)",
      top: 22,
      "& *": {
        background: "transparent",
        color: theme.palette.text.primary,
        fontSize: "1rem",
        fontWeight: 500,
      },
    },
    track: {
      height: 10,
      borderRadius: 10,
    },
    rail: {
      height: 10,
      borderRadius: 10,
      background: "rgba(75, 66, 68, 0.4)",
    },
    mark: {
      backgroundColor: "#bfbfbf",
      height: 8,
      width: 1,
      marginTop: -3,
    },
    markActive: {
      opacity: 1,
      backgroundColor: "red",
    },
  })
)(Slider);

function valuetext(value: number) {
  return `${value}`;
}

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      border: "none",
    },
  })
)(TableRow);

const StyleTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 4,
    },
    head: {
      color: theme.palette.text.secondary,
      fontWeight: 400,
      fontSize: 10,
      border: "none",
      lineHeight: 0,
    },
    body: {
      fontSize: 12,
      border: "none",
      color: "#000",
      fontWeight: 400,
    },
  })
)(TableCell);
const options = {
  unit: "pt",
  format: "a4",
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
};
export const EmplyLogo = (props) => {
  const { classes } = props;
  return (
    <Grid item xs={2}>
      <Box className={classes.logoBox}>
        <img src={addImg} className={classes.defaultlogoImage} />
        <Typography variant="body2" display="block">
          Your logo here
        </Typography>
      </Box>
    </Grid>
  );
};
export const DateComponent = () => {
  return (
    <Grid item xs={3}>
      <CustomTypography
        color="textSecondary"
        fontSize="1.1rem"
        fontWeight={700}
        align="right"
        padding="0 1rem 0 0"
      >
        {moment(new Date()).format("MM-DD-YYYY")}
      </CustomTypography>
    </Grid>
  );
};

const svgCode = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>`;
export const packages_annual = [
  "New Business Incubator",
  "IRS Rapid Relief Package",
  "Reporting-Only Package (Individual)",
  "Reporting-Only Package (Business)",
];
export const packages_monthly = [
  "Business Bundle",
  "Business Tax & Advisory",
  "Accounting Success Package",
  "Payroll Success Package",
  "Personal Bundle",
];
const noOfmonthForBalanceSheets = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, "12"];
export const getProity = (props, classes) => {
  const data = props.roadMap?.attributes?.priorities?.data.length
    ? props.roadMap?.attributes?.priorities?.data
    : [
        { attributes: { name: "NA" } },
        { attributes: { name: "NA" } },
        { attributes: { name: "NA" } },
      ];
  return (
    <CustomGrid item xs={10} width="90%" className={classes.innerWhiteCard}>
      <CustomTypography
        color="textPrimary"
        fontSize="1.7rem"
        fontWeight={700}
        margin="1rem 0 0.5rem 0"
      >
        Progress you would like to make in the next 12 months:
      </CustomTypography>
      <Box
        style={{
          display: "flex",
          gap: 20,
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        <CustomGrid item xs={12} className={classes.lightGreyCard}>
          <CustomTypography
            color="textSecondary"
            fontSize="1.1rem"
            fontWeight={700}
            padding="0 0 0 0.75rem"
            style={{ maxWidth: "200px", overflow: "hidden" }}
          >
            {data[0].attributes.name}
          </CustomTypography>
        </CustomGrid>
        <span>
          <div
            dangerouslySetInnerHTML={{ __html: svgCode }}
            className={classes.iconStyle2}
          />
        </span>
        <CustomGrid item xs={12} className={classes.lightGreyCard}>
          <CustomTypography
            color="textSecondary"
            fontSize="1.1rem"
            fontWeight={700}
            padding="0 0 0 0.75rem"
            style={{ maxWidth: "200px", overflow: "hidden" }}
          >
            {data[1].attributes.name}
          </CustomTypography>
        </CustomGrid>
        <span>
          <div
            dangerouslySetInnerHTML={{ __html: svgCode }}
            className={classes.iconStyle2}
          />
        </span>
        <CustomGrid item xs={12} className={classes.lightGreyCard}>
          <CustomTypography
            color="textSecondary"
            fontSize="1.1rem"
            fontWeight={700}
            padding="0 0 0 0.75rem"
            style={{ maxWidth: "200px", overflow: "hidden" }}
          >
            {data[2].attributes.name}
          </CustomTypography>
        </CustomGrid>
      </Box>
    </CustomGrid>
  );
};

export class ValueRoadmap extends ValueRoadmapController {
  constructor(props: Props) {
    super(props);
    this.pdfRef = React.createRef();
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    const clientId = await StorageProvider.get("clientId");
    const roadMapId =
      this.props.history?.location?.state?.roadMapId ||
      (await StorageProvider.get("roadMapId"));
    const surveyId =
      this.props.history?.location?.state?.surveyId ||
      (await StorageProvider.get("surveyId"));
    const surveyType =
      this.props.history?.location?.state?.surveyType ||
      (await StorageProvider.get("surveyType"));
    this.setState(
      {
        token: token,
        accountId: accountId,
        clientId: clientId,
        surveyId: surveyId || null,
        surveyType: surveyType,
        roadMapId: roadMapId == "null" ? null : roadMapId,
      },
      () => {
        if (
          !this.props.permissions.includes(
            "Access Survey Responses-Client Conversion Survey"
          )
        ) {
          this.props.showToast({
            type: "error",
            message: "AccessDenied for Current User",
          });
          this.props.history.push("/home/clientsList/details/overview", {
            clientId: this.state.clientId,
          });
        } else {
          this.getClientDetail();
        }
      }
    );
  }
  handleSurveyChange = (event) => {
    this.setState(
      {
        selectedPackageForSurvey: null,
        selectedSurvey:
          event.target.value.split("_")[0] +
          "_" +
          event.target.value.split("_")[1],
        selectedSurveyType: event.target.value.split("_")[1],
        isSelectedByClient: true,
      },
      () => {
        StorageProvider.set("package_management_id", "");
        if (this.state.selectedSurveyType == "roadmap") {
          this.getRoadMapForValueRoadmap(event.target.value.split("_")[0]);
        } else {
          this.getAllQuestionbySurveyType();
        }
      }
    );
  };
  handlePackageSelection = (pkg) => {
    this.setState({
      selectedPackageForSurvey: pkg,
      isSelectedByCpa: true,
    });
  };
  downloadPdf = async (pdfRef) => {
    const option = {
      margin: 2,
      filename: "roadmap.pdf",
      pagebreak:{
        before : '#exeee'
      },
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "pt", format: "a4" },
    };
    html2pdf()
      .set(option)
      .from(pdfRef.current)
      .save();
  }

  renderSelectBar = (pdfRef, classes) => {
    return (
      <>
        <Grid
          xs={3}
          item
          style={{
            marginLeft: "3rem",
            marginBottom: "5rem",
          }}
        >
          <TextField
            variant="outlined"
            value={this.state.selectedSurvey}
            name="selectedSurvey"
            onChange={this.handleSurveyChange}
            select
            fullWidth
          >
            {this.state.surveyList.map((survey) => {
              return survey?.type == "roadmap" ? (
                <MenuItem key={survey.id} value={`${survey.id}_roadmap`}>
                  RoadMap {survey.name}
                </MenuItem>
              ) : (
                <MenuItem
                  key={survey.id}
                  value={`${survey.id}_${survey?.attributes?.about_us?.data?.attributes?.survey_type}`}
                >
                  Conversion survey{" "}
                  {moment(survey?.attributes?.created_at).format("DD/MM/YYYY")}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid
          xs={3}
          item
          style={{
            marginRight: "3rem",
            marginBottom: "5rem",
          }}
        >
          {this.state.selectedSurveyType == "roadmap" && (
            <>
            <Button
              fullWidth
              variant="contained"
              id="downloadRoadmapPdf"
              onClick={this.downloadPdf.bind(this,pdfRef)}
              className={classes.primaryColorButton}
            >
              View Roadmap
            </Button>
            <Button
              fullWidth
              variant="contained"
              id="downloadRoadmapPdf"
              className={classes.primaryColorButton}
              style={{
                marginTop : "10px"
              }}
              onClick={this.openClientInfoDialog.bind(this)}
            >
              Client Information
            </Button>
            </>
          )}
        </Grid>
      </>
    );
  };

  openClientInfoDialog = () => {
    this.props
      .openDialogBox({
        dataToPass: {
          info : true,
          clientInfo : this.state.clientInfo,
          isDataAvailable : this.state.isDataAvailable
        },
        width: "100%",
        renderedComponent: ViewDocumentDialogWeb,
        withCustomDialog: false,
      })
  };


  processAnnualIncome = (classes, question) => {
    return (
      <Grid xs={10} item key={question?.attributes?.stepType}>
        <div className={classes.questionHeading}>
          <div className={classes.questionHeadinglist}>
            {question.attributes.description}
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            margin: "16px auto",
            width: "40%",
          }}
        >
          <TextField
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography className={classes.dollarSignSmall}>$</Typography>
                </InputAdornment>
              ),
            }}
            placeholder="Total Annual Revenue"
            fullWidth
            name="annualRevenue"
            variant="outlined"
            value={question?.attributes?.value}
            disabled
            label=""
          />
        </div>
      </Grid>
    );
  };
  processTaxFilling = (classes, question) => {
    return (
      <Grid xs={10} item key={question?.attributes?.stepType}>
        <div className={classes.questionHeading}>
          <div className={classes.questionHeadinglist}>
            {question?.attributes?.description}
          </div>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.statusHeading}>
                  Select your status
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    value={this.state.taxFillingStatus}
                    name="taxFillingStatus"
                    variant="outlined"
                    select
                    disabled
                  >
                    {[
                      "Single",
                      "Head of household",
                      "Married filing Single",
                      "Married filing joint",
                      "Not Sure/Need Recommendation",
                    ].map((status) => {
                      return (
                        <MenuItem key={status} value={status}>
                          {status}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyleTableCell />
                    <StyleTableCell>
                      <div />
                    </StyleTableCell>
                    <StyleTableCell align="center">Yes</StyleTableCell>
                    <StyleTableCell align="center">No</StyleTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {question?.attributes?.options.map((row) => (
                    <StyledTableRow key={row.key}>
                      <StyleTableCell scope="row">
                        <Typography
                          style={{
                            backgroundColor: "#Fff",
                            padding: "8px",
                            borderRadius: "10px",
                          }}
                          className={classes.pageSubHeading}
                        >
                          {row?.name}
                        </Typography>
                      </StyleTableCell>
                      <StyleTableCell>
                        <div
                          style={{
                            visibility: "hidden",
                          }}
                        >
                          aaaaaaa
                        </div>
                      </StyleTableCell>
                      <StyleTableCell align="right">
                        <Button
                          fullWidth
                          style={
                            row?.value === "Yes"
                              ? {
                                  background: "#5BA8AA",
                                  color: "#fff",
                                  width: "60%",
                                }
                              : {
                                  color: "#ccc",
                                  background: "#fff",
                                  width: "60%",
                                }
                          }
                          variant="contained"
                          color="primary"
                        >
                          <CheckOutlined />
                        </Button>
                      </StyleTableCell>
                      <StyleTableCell align="right">
                        <Button
                          fullWidth
                          style={
                            row?.value === "No"
                              ? {
                                  background: "#F96064",
                                  color: "#fff",
                                  width: "60%",
                                }
                              : {
                                  color: "#ccc",
                                  background: "#fff",
                                  width: "60%",
                                }
                          }
                          variant="contained"
                          color="default"
                        >
                          <CloseOutlined />
                        </Button>
                      </StyleTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid xs={9} item>
                <Typography
                  style={{
                    backgroundColor: "#Fff",
                    padding: "8px",
                    borderRadius: "10px",
                    width: "74%",
                    fontWeight: 600,
                  }}
                  className={classes.pageSubHeading}
                >
                  How many states do you earn income in?
                </Typography>
              </Grid>
              <Grid xs={3} item>
                <TextField
                  disabled={true}
                  value={this.state.noOfStateIncomeEarnIn}
                  variant="outlined"
                  fullWidth
                  name="noOfStateIncomeEarnIn"
                  select
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
                    return item === 1 ? (
                      <MenuItem disabled={true} value={0} key={item}>
                        {item}
                      </MenuItem>
                    ) : (
                      <MenuItem value={item} key={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  processBusinessDetail = (classes, question) => {
    return (
      <Grid item xs={10}>
        <div>
          <Typography className={classes.questionHeadinglist} gutterBottom>
            Tell us a little bit about your business
          </Typography>
        </div>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyleTableCell />
                <StyleTableCell>
                  <div />
                </StyleTableCell>
                <StyleTableCell align="center">
                  <div />
                </StyleTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {question?.attributes?.options.map((row) => (
                <StyledTableRow key={row.key}>
                  <StyleTableCell scope="row">
                    <Typography
                      style={{
                        backgroundColor: "#Fff",
                        padding: "8px",
                        borderRadius: "10px",
                      }}
                      className={classes.pageSubHeading}
                    >
                      {row?.name}
                    </Typography>
                  </StyleTableCell>
                  <StyleTableCell>
                    <div
                      style={{
                        visibility: "hidden",
                      }}
                    >
                      aaaaaaa
                    </div>
                  </StyleTableCell>
                  <StyleTableCell align="right">
                    <TextField
                      value={row?.value}
                      name={row?.key}
                      variant="outlined"
                      disabled
                    />
                  </StyleTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  };
  processAccounting = (classes, question) => {
    return (
      <Grid item xs={10}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item xs={12}>
            <Typography className={classes.questionHeadinglist} gutterBottom>
              {"Tell us about your accounting"}
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  classes={{ root: classes.buttonRoot }}
                  variant="outlined"
                  color="default"
                  style={
                    this.state.isHelpNeededinAccounting
                      ? {
                          background: "#5BA8AA",
                          color: "#fff",
                        }
                      : {
                          color: "#ccc",
                          background: "#fff",
                        }
                  }
                >
                  Yes
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  classes={{ root: classes.buttonRoot }}
                  variant="outlined"
                  color="default"
                  style={
                    !this.state.isHelpNeededinAccounting
                      ? {
                          background: "#F96064",
                          color: "#fff",
                        }
                      : {
                          color: "#ccc",
                          background: "#fff",
                        }
                  }
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.question} gutterBottom>
              How many bank and/or credit card account do you have for your
              business?
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <div
              style={{
                position: "relative",
                width: "100%",
              }}
            >
              {this.state.noOFBankAccount != 0 && (
                <span
                  className={classes.sliderLabel}
                  style={{
                    position: "absolute",
                    display: "inline-block",
                    left: -16,
                    top: 10,
                  }}
                >
                  0
                </span>
              )}
              {this.state.noOFBankAccount != 10 && (
                <span
                  className={classes.sliderLabel}
                  style={{
                    position: "absolute",
                    display: "inline-block",
                    right: 100,
                    top: 10,
                  }}
                >
                  10
                </span>
              )}
              <PrettoSlider
                aria-labelledby="track-false-slider"
                marks={false}
                valueLabelDisplay="on"
                aria-label="pretto slider"
                step={1}
                min={0}
                max={10}
                value={this.state.noOFBankAccount}
              />
            </div>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.question} gutterBottom>
              Do you want us to manage sending/receiving invoices?
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  classes={{ root: classes.buttonRoot }}
                  variant="outlined"
                  color="default"
                  style={
                    this.state.isManageInvoice
                      ? {
                          background: "#5BA8AA",
                          color: "#fff",
                        }
                      : {
                          color: "#ccc",
                          background: "#fff",
                        }
                  }
                >
                  Yes
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  classes={{ root: classes.buttonRoot }}
                  variant="outlined"
                  color="default"
                  style={
                    !this.state.isManageInvoice
                      ? {
                          background: "#F96064",
                          color: "#fff",
                        }
                      : {
                          color: "#ccc",
                          background: "#fff",
                        }
                  }
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.question} gutterBottom>
              Generally, how many invoices do you send/receive monthly?
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <div
              style={{
                position: "relative",
                width: "100%",
              }}
            >
              {this.state.noOFInvoice != 0 && (
                <span
                  className={classes.sliderLabel}
                  style={{
                    position: "absolute",
                    display: "inline-block",
                    left: -16,
                    top: 10,
                  }}
                >
                  0
                </span>
              )}
              {this.state.noOFInvoice != 50 && (
                <span
                  className={classes.sliderLabel}
                  style={{
                    position: "absolute",
                    display: "inline-block",
                    right: 100,
                    top: 10,
                  }}
                >
                  50+
                </span>
              )}
              <PrettoSlider
                aria-labelledby="track-false-slider"
                marks={false}
                getAriaValueText={valuetext}
                valueLabelDisplay="on"
                aria-label="pretto slider"
                min={0}
                max={50}
                value={this.state.noOFInvoice}
              />
            </div>
          </Grid>
          {this.state.currentAccountingSystem !== "None" && (
            <>
              <Grid item xs={8}>
                <Typography className={classes.question} gutterBottom>
                  Are your balance sheet and profit statement completely
                  accurate within the last month?
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      classes={{
                        root: classes.buttonRoot,
                      }}
                      variant="outlined"
                      color="default"
                      style={
                        this.state.isBalanceSheetAccurate
                          ? {
                              background: "#5BA8AA",
                              color: "#fff",
                            }
                          : {
                              color: "#ccc",
                              background: "#fff",
                            }
                      }
                    >
                      Yes
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      classes={{
                        root: classes.buttonRoot,
                      }}
                      variant="outlined"
                      color="default"
                      style={
                        this.state.isBalanceSheetAccurate
                          ? {
                              color: "#ccc",
                              background: "#fff",
                            }
                          : {
                              background: "#F96064",
                              color: "#fff",
                            }
                      }
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* {!props.isBalanceSheetAccurate && ( */}
              <Grid item xs={9}>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <Typography className={classes.question} gutterBottom>
                      If not, How many months are you behind on your accounting?
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl className={classes.formControl}>
                      <TextField
                        select
                        disabled={this.state.isBalanceSheetAccurate}
                        value={this.state.noOfmonthForBalanceSheet}
                        name="noOfmonthForBalanceSheet"
                        variant="outlined"
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                      >
                        <MenuItem value="">
                          <em>Select</em>
                        </MenuItem>
                        {noOfmonthForBalanceSheets.map((balsheet) => {
                          return (
                            <MenuItem key={balsheet} value={balsheet}>
                              {balsheet}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* )} */}
            </>
          )}
        </Grid>
      </Grid>
    );
  };
  renderSurveyDetail = (classes) => {
    return (
      <Grid container justifyContent="center" spacing={4}>
        {this.state.questionList.map((question) => {
          let element = null;
          if (question?.type === "customQuestion") {
            switch (question?.attributes?.stepType) {
              case "AnnualIncome":
                element = this.processAnnualIncome(classes, question);
                break;
              case "TaxFilling":
                element = this.processTaxFilling(classes, question);
                break;
              case "BusinessDetail":
                element = this.processBusinessDetail(classes, question);
                break;
              case "Accounting":
                element = this.processAccounting(classes, question);
            }
          } else {
            element = (
              <Grid xs={10} item key={question.id}>
                <div className={classes.questionHeading}>
                  <div className={classes.questionHeadinglist}>
                    {question.attributes.description}
                  </div>
                  <div>
                    {question.attributes.options.data
                      .sort((a, b) => {
                        if (a?.attributes?.order_no > b?.attributes?.order_no) {
                          return 1;
                        } else if (
                          b?.attributes?.order_no > a?.attributes?.order_no
                        ) {
                          return -1;
                        } else {
                          return 0;
                        }
                      })
                      .map((options) => {
                        return (
                          <div key={options?.id}>
                            <FormControlLabel
                              key={options.id}
                              classes={{
                                label: classes.options,
                              }}
                              checked={options?.isSelected}
                              control={
                                <Checkbox
                                  name={`${options.id}`}
                                  defaultChecked={options?.isSelected || false}
                                  color="primary"
                                />
                              }
                              label={`${options.attributes.description}`}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Grid>
            );
          }
          return element;
        })}
      </Grid>
    );
  };
  renderPackageList = (classes) => {
    return (
      <Grid container justifyContent="center">
        {this.state?.packageList?.length > 0 &&
          this.state.packageList.map((m, index) => {
            let pkg_price = null;
            let pkg_price_ferq = null;
            if (packages_annual.indexOf(m.name) > -1) {
              pkg_price = formatCurrency.format(Math.round(m.price));
              pkg_price_ferq = "One Time";
            } else if (packages_monthly.indexOf(m.name) > -1) {
              pkg_price = formatCurrency.format(Math.round(m.price / 12));
              pkg_price_ferq = "Monthly";
            }
            return (
              <Grid
                key={m.id}
                item
                xs={12}
                md={4}
                className={
                  this.state.selectedPackageForSurvey &&
                  this.state.selectedPackageForSurvey?.id === m.id
                    ? classes.cardPackageSelected
                    : classes.cardPackage
                }
                onClick={(event) => {
                  if (!this.state.isSelectedByClient) {
                    this.handlePackageSelection(m);
                  }
                }}
              >
                <Grid container alignItems="space-between">
                  <Grid item xs={12}>
                    <Typography
                      className={classes.optionTitle}
                      color="textSecondary"
                      align="center"
                    >
                      {m.name}
                    </Typography>
                    <Typography
                      className={classes.optionPriceLarge}
                      color="primary"
                      align="center"
                    >
                      $ {pkg_price}
                      <Typography
                        className={classes.optionPriceSmall}
                        color="primary"
                        align="center"
                        display="inline"
                      >
                        {pkg_price_ferq}
                      </Typography>
                    </Typography>
                    <Divider />
                    <div className={classes.featureListContainer}>
                      {m?.feature_name_list?.map((feature) => {
                        return (
                          <Typography
                            key={feature}
                            color="textSecondary"
                            className={classes.featureText}
                          >
                            {feature}
                          </Typography>
                        );
                      })}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    );
  };
  selectPaymentTerm = (m) => {
    if (!this.state.isSelectedByClient) {
      this.handlePackageSelection(m);
    }
  };
  renderCheckbox = () => {
    return (
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={8}>
            <div style={{ textAlign: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!this.state.isSelectedByCpa}
                    checked={this.state.dont_allow_to_change_package_id}
                    onChange={(event) => {
                      this.setState({
                        dont_allow_to_change_package_id: event.target.checked,
                      });
                    }}
                    color="primary"
                  />
                }
                label="Don't Allow Client to Change Option in Onboarding Survey"
              />
            </div>
          </Grid>
          <Grid item xs={8} style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              disabled={!this.state.selectedPackageForSurvey}
              onClick={this.addChecklist}
              color="primary"
            >
              Onboard this Client
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  addChecklist = () => {
    this.state.roadMap
      ? this.createChecklistforSelectedPackageValueRoadmapInRoadmap()
      : this.createChecklistforSelectedPackageValueRoadmap();
  }

  render() {
    const { classes } = this.props;
    return this.state.surveyList?.length > 0 ? (
      <Grid
        container
        justifyContent="space-between"
        style={{
          position: "relative",
        }}
      >
        <Grid item xs={12} className={[classes.gridSpacing, classes.whiteCard]}>
          <Grid container justifyContent="space-between">
            {this.renderSelectBar(this.pdfRef, classes)}
            {this.state.selectedSurveyType == "roadmap" &&
            this.state.roadMap ? (
              <>
                <Grid
                  container
                  ref={this.pdfRef}
                  style={{ overflowX: "hidden" }}
                  justifyContent="center"
                  className={classes.rootChildContainerpdf}
                >
                  <RoadMapUIWeb
                    logo={this.state.roadMap?.attributes?.add_logo}
                    roadmap={this.state.roadMap}
                    packagePaymentTerms={this.state.paymentTermsForRoadmap}
                    selectedPaymentTerm={this.state.selectedPackageForSurvey}
                    isPackageSelectable={true}
                    handleSelectPaymentTerm={this.selectPaymentTerm}
                  />
                </Grid>
                {/* <EmailTemplate
                  ref={this.pdfRef}
                  roadMap={this.state.roadMap}
                  selectedPackageForSurvey={this.state.selectedPackageForSurvey}
                  paymentTermsForRoadmap={this.state.paymentTermsForRoadmap}
                  selectPaymentTerm={this.selectPaymentTerm}
                  {...this.props}
                /> */}
                {this.renderCheckbox()}
              </>
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  className={[classes.pricingBumpsContainer, classes.greyCard]}
                >
                  <Grid container>
                    <Grid xs={12} item>
                      <Grid container justifyContent="flex-end">
                        <Grid xs={2} item>
                          <div>
                            <Typography
                              className={classes.date}
                              component={"span"}
                              color="primary"
                            >
                              Date
                            </Typography>
                            :
                            <Typography
                              className={classes.datevalue}
                              component={"span"}
                              color="textPrimary"
                            >
                              {moment(this.state.date).format("DD/MM/YYYY")}
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              className={classes.date}
                              component={"span"}
                              color="primary"
                            >
                              Hours
                            </Typography>
                            :
                            <Typography
                              className={classes.datevalue}
                              component={"span"}
                              color="textPrimary"
                            >
                              {moment(this.state.date).format("hh:mm:ss")}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={12} item>
                      <Typography
                        align="center"
                        className={classes.mainHeading}
                        color="primary"
                      >
                        Client Conversion Survey
                      </Typography>
                    </Grid>
                    <Grid xs={12} item>
                      {this.renderSurveyDetail(classes)}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {this.renderPackageList(classes)}
                </Grid>
                {this.renderCheckbox()}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    ) : (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          position: "relative",
        }}
        // spacing={4}
      >
        <Grid item xs={12} className={[classes.gridSpacing, classes.whiteCard]}>
          <Typography align="center" variant="h1" color="textSecondary">
            No survey available
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export const templateStyle = (theme) => {
  return {
    incomeContainer: {
      width: "60%",
      margin: "auto",
      height: "50vh",
    },
    packagePageHeading: {
      fontSize: "1.8rem",
      fontWeight: 700,
      marginTop: "2rem",
    },
    packagePageSubHeading: {
      fontSize: "1.3rem",
      // fontWeight: 500,
    },
    cardPackage: {
      minHeight: "30rem",
      borderRadius: "1.8rem",
      backgroundColor: "#ffffff",
      boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.1)",
      border: `2px solid white`,
      padding: "1rem",
      margin: "3% 1%",
      display: "flex",
      justifyContent: "center",
      flexGrow: 0,
      maxWidth: "25%",
      flexBasis: "27%",
      [theme.breakpoints.down("sm")]: {
        flexGrow: 0,
        maxWidth: "100%",
        flexBasis: "100%",
        marginRight: 0,
      },
      "@media( max-width:400px)": {
        maxWidth: "100%",
      },
      "@media(max-width:960px )and (min-width:400px)": {
        maxWidth: "50%",
      },
    },
    cardPackageSelected: {
      border: `1px solid ${theme.palette.primary.main}`,
      minHeight: "30rem",
      borderRadius: "1.8rem",
      backgroundColor: "#ffffff",
      boxShadow: `0px 0px 12px 0px ${theme.palette.primary.main}`,
      padding: "1rem",
      margin: "3% 1%",
      display: "flex",
      justifyContent: "center",
      flexGrow: 0,
      maxWidth: "25%",
      flexBasis: "27%",
      [theme.breakpoints.down("sm")]: {
        flexGrow: 0,
        maxWidth: "100%",
        flexBasis: "100%",
        marginRight: 0,
      },
      "@media( max-width:400px)": {
        maxWidth: "100%",
      },
      "@media(max-width:960px )and (min-width:400px)": {
        maxWidth: "50%",
      },
    },
    optionTitle: {
      fontSize: "1.3rem",
      fontWeight: 700,
      minHeight: "43px",
    },
    optionPriceLarge: {
      fontSize: "1.8rem",
      fontWeight: 700,
      marginBottom: "0.5rem",
    },
    optionPriceSmall: {
      fontSize: "1rem",
      fontWeight: 600,
      marginBottom: "0.5rem",
      marginLeft: "0.25rem",
    },
    featureText: {
      fontSize: "1.1rem",
      marginBottom: "0.5rem",
      margin: "0.6rem",
      paddingLeft: "1.25rem",
      position: "relative",
      "&::before": {
        position: "absolute",
        content: "''",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "50%",
        left: 0,
        top: "0.4rem",
        height: "0.625rem",
        width: "0.625rem",
      },
    },
    bottomDivider: {
      height: "1px",
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      width: "90%",
    },
    cardPackageBottomText: {
      fontSize: "1.1rem",
      fontWeight: 600,
      paddingTop: "1rem",
      borderTop: "1px solid rgba(0, 0, 0, 0.08)",
    },
    accordionHeading: {
      paddingLeft: "1rem",
      fontSize: "1.4rem",
      fontWeight: 700,
      marginBottom: "0.7rem",
    },
    accordionRound: {
      borderRadius: `${theme.spacing(2)}px!important`,
    },
    accordionExpandIcon: {
      background: theme.palette.primary.main,
      borderRadius: "50%",
      color: "#fff",
      padding: "2px",
      marginRight: "-6px",
      "& svg": {
        fontSize: "1.2rem",
      },
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
    accordionWhite: {
      boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.1)",
      borderRadius: "2rem",
      padding: "0.5rem 0",
    },
    checkedIcon: {
      minWidth: 20,
      height: 20,
      display: "flex",
      alignItems: "center",
      color: localStorage.getItem("primaryColor") || theme.palette.primary.main,
    },
    featureListStyle: {
      "&::-webkit-scrollbar": {
        width: "2px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#fff",
        borderRadius: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#C7C7C8",
        borderRadius: "8px",
      },
    },
    featureListContainer: {
      maxHeight: "30rem",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "2px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#fff",
        borderRadius: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#C7C7C8",
        borderRadius: "8px",
      },
    },
    mainheadingStyle: {
      alignItems: "left",
      justifyContent: "left",
      borderBottom: `1px solid ${localStorage.getItem("primaryColor") ||
        theme.palette.primary.main}`,
    },
    whiteCardRoadmap: {
      marginTop: "1rem",
    },
    innerWhiteCard: {
      padding: "1.5rem 1.5rem 1.5rem 0",
      borderRadius: "1rem",
      backgroundColor: "#ffffff",
    },
    lightGreyCard: {
      padding: "0.5rem 0.5rem 0.5rem 0",
      borderRadius: "0.3rem",
      margin: "0.5rem 0",
    },
    paddingLeft: {
      [theme.breakpoints.up("md")]: {
        paddingLeft: theme.spacing(3),
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: theme.spacing(2),
      },
    },
    primaryColorButton: {
      backgroundColor:
        localStorage.getItem("primaryColor") || theme.palette.primary.main,
      color: "#ffffff",
    },
    boxShadow: {
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(4),
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(4),
      },
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(2),
      },
      border: `1px solid ${localStorage.getItem("secondaryColor")}`,
      boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.08)",
      borderRadius: "12px",
    },
    marginTop: {
      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(4),
      },
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(3),
      },
    },
    marginBottom: {
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(4),
      },
      [theme.breakpoints.down("md")]: {
        marginBottom: theme.spacing(3),
      },
    },
    packageCardHeadingBox: {
      padding: "0 1rem",
      borderRadius: "0.75rem",
      textAlign: "center",
      marginBottom: "0.25rem",
    },
    iconStyle2: {
      fill: localStorage.getItem("primaryColor") || theme.palette.primary.main,
      width: "13px",
    },
  };
};

const ValueRoadmapWithRouter = withRouter(ValueRoadmap);
const ValueRoadmapAlertBox = withAlertBox(ValueRoadmapWithRouter);
const ValueRoadmapLoader = withLoader(ValueRoadmapAlertBox);
const ValueRoadmapToast = withToast(ValueRoadmapLoader);
const ValueRoadmapWithDialog = withDialog(ValueRoadmapToast);
const ValueRoadmapWithConfirmBox = withConfirmBox(ValueRoadmapWithDialog);
const ValueRoadmapWithPermissions = withPermissions(ValueRoadmapWithConfirmBox);

export default withStyles(
  (theme) =>
    createStyles({
      gridSpacing: {
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      whiteCard: {
        padding: theme.spacing(4),
        borderRadius: theme.spacing(2),
        backgroundColor: "#ffffff",
        boxShadow: `0px 4px 18px rgba(0,0,0,0.1)`,
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(1.5),
        },
      },
      greyCard: {
        padding: theme.spacing(8),
        borderRadius: theme.spacing(2),
        backgroundColor: "#f8f8f8",
        boxShadow: `0px 4px 18px rgba(0,0,0,0.1)`,
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(1.5),
        },
      },
      mainHeading: {
        fontFamily: "Nunito Sans",
        fontSize: "2rem",
        fontWeight: 700,
        display: "block",
        margin: "1rem 0",
      },
      pricingBumpHeading: {
        fontFamily: "Nunito Sans",
        fontSize: "1.1rem",
        fontWeight: 700,
      },
      heading: {
        fontFamily: "Nunito Sans",
        fontSize: "1.3158rem",
        fontWeight: 700,
        color: theme.palette.text.blue2,
      },
      questionHeadinglist: {
        fontWeight: 700,
        fontSize: "1.4rem",
      },
      options: {
        fontWeight: 400,
        fontSize: "1.4rem",
        color: theme.palette.text.secondary,
      },
      questionHeading: {
        "& > li::marker": {
          fontSize: "1.2rem",
          color: theme.palette.primary.main,
        },
      },
      pricingBumpsContainer: {
        margin: "1rem 3rem",
        padding: "1.2rem",
      },
      date: {
        fontWeight: 700,
        fontSize: "1.2rem",
      },
      datevalue: {
        fontWeight: 600,
        fontSize: "1.2rem",
      },
      rootChildContainerpdf: {
        [theme.breakpoints.up("md")]: {
          padding: "1.5rem 2rem 2rem 2rem",
        },
        [theme.breakpoints.down("md")]: {
          padding: "1rem",
        },
      },
      ...templateStyle(theme),
      ...roadmapStyles(theme),
    }),
  { withTheme: true }
)(ValueRoadmapWithPermissions);
