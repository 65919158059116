//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  createStyles,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import RoadmapPackageSelectionByClientController from "./RoadmapPackageSelectionByClientController.web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import ConfirmEmailDialog from "./RoadmapConfirmEmailDialog.web";
import AddNewClientDialog from "../ClientList/AddNewClientDialog.web";
import RoadMapUIWeb, { roadmapStyles } from "./RoadMapUI.web";
import DisclaimerDialog from "../DisclaimerDialog.web";


export class RoadmapPackageSelectionByClient extends RoadmapPackageSelectionByClientController {
  async componentDidMount() {
    const query = new URLSearchParams(this.props.history.location.search);
    const surveyId = query.get("roadmapId");
    const queryParams = new URLSearchParams(atob(surveyId));
    const accountId = queryParams?.get("accountId");
    const clientId = queryParams?.get("clientId");

    const roadmapId = queryParams?.get("roadmapId");
    if (roadmapId) {
      this.setState(
        {
          accountId,
          clientId,
          roadmapId,
        },
        () => {
          this.getCPADetialbyAccessTokenForRoadmap();
        }
      );
    }
  }
  openDisclaimerDialog = (pkg: any) => {
    this.props
      .openDialogBox({
        width: "40%",
        renderedComponent: DisclaimerDialog,
        withCustomDialog: true,
        catchOnCancel: true,
      })
      .then(async (data: any) => {
        if (data.result === "Yes") {
          this.setState(
            {
              selectedPackageForRoadmap: pkg,
            },
            this.createChecklistforSelectedPackageInRoadmap
          );
        }
      });
  };


  openAddClientDialog = (paymentTerm) => {
    this.props
      .openDialogBox({
        dataToPass: {
          showToast: this.props.showToast.bind(this),
          isNotShowCustomField: true,
          isFromRoadMap: true,
          accountId: this.state.accountId,
        },
        width: "45%",
        renderedComponent: AddNewClientDialog,

        disableBackdropClick: true,
        disableEscapeKeyDown: true,

        withCustomDialog: false,
      })
      .then((data: any) => {
        if (data?.id) {
          this.setState(
            {
              clientId: data?.id,
              clientDetail: data,
              selectedPackageForRoadmap: paymentTerm,
            },
            this.linkRoadmapToClient
          );
        }
      });
  };
  selectPaymentTerm = (paymentTerm) => {
    if (this.state.clientId) {
      if (!this.state.isAlreadySelectedByClient) {
        this.openDisclaimerDialog(paymentTerm);
      } else {
        this.props.showToast({
          type: "error",
          message: "Package selection is not allowed",
        });
      }
    } else {
      this.openAddClientDialog(paymentTerm);
    }
  };
  render() {
    const { classes } = this.props;

    return this.state.isEmailSent ? (
      <Grid
        container
        item
        xs={12}
        className={classes.mainContainerRoadmap}
        justifyContent="center"
        alignItems="center"
      >
        <Grid xs={8} item style={{ textAlign: "center" }}>
          <Typography
            color="primary"
            style={{ textAlign: "center", fontSize: "2.5rem" }}
            variant="h1"
          >
            Thank you
          </Typography>
          <Typography color="textPrimary" variant="h1">
            We have sent an onboarding email for next step
          </Typography>
        </Grid>
      </Grid>
    ) : (
      <Grid container justifyContent="center" className={classes.root}>
        {this.state.isLoadingPage ? (
          <Grid
            container
            item
            xs={12}
            className={classes.mainContainerRoadmap}
            justifyContent="center"
            alignItems="center"
          >
            <Grid xs={8} item>
              <LinearProgress
                style={{ borderRadius: "24px" }}
                color="primary"
              />
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            justifyContent="center"
            item
            xs={10}
            className={classes.rootChildContainerpdf}
          >
            <RoadMapUIWeb
              logo={this.state.roadMap?.attributes?.add_logo}
              roadmap={this.state.roadMap}
              packagePaymentTerms={this.state.selectedPaymentTerms}
              selectedPaymentTerm={this.state.selectedPackageForRoadmap}
              isPackageSelectable={true}
              handleSelectPaymentTerm={this.selectPaymentTerm}
            />
          </Grid>
        )}
      </Grid>
    );
  }
}
const RoadmapPackageSelectionByClientWithRouter = withRouter(
  RoadmapPackageSelectionByClient
);
const RoadmapPackageSelectionByClientWithToast = withToast(
  RoadmapPackageSelectionByClientWithRouter
);
const RoadmapPackageSelectionByClientWithLoader = withLoader(
  RoadmapPackageSelectionByClientWithToast
);
const RoadmapPackageSelectionByClientWithAlertBox = withAlertBox(
  RoadmapPackageSelectionByClientWithLoader
);
const RoadmapPackageSelectionByClientWithDialogBox = withDialogBox(
  RoadmapPackageSelectionByClientWithAlertBox
);

export default withStyles((theme) =>
  createStyles({
    ...roadmapStyles,
    root: {
      height: "100vh",
      overflowY: "auto",
      overflowX: "hidden",
    },
    rootChildContainerpdf: {
      [theme.breakpoints.up("md")]: {
        padding: "1.5rem 2rem 2rem 2rem",
      },
      [theme.breakpoints.down("md")]: {
        padding: "1rem",
      },
    },
    mainContainerRoadmap: {
      height: "calc(100vh - 30vh)",
      maxHeight: "calc(100vh - 30vh)",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "2px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-track": {
        // boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
        backgroundColor: "#fff",
        borderRadius: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#C7C7C8",
        borderRadius: "8px",
      },
    },
  })
)(RoadmapPackageSelectionByClientWithDialogBox);
