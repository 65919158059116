//@ts-nocheck
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";
import _ from "underscore";
import { formatCurrency } from "../CustomPackage/CustomPackageCreationSelectFeaturesPage.web";

export const configJSON = require("../config");
export const processPaymentTerms = (values: any) => {
  const paymentTerms = [];
  const payment_term = values.data.attributes.payment_term;
  const pkg = values.data.attributes;
  calculateMonthlyPaymentTerm(payment_term, paymentTerms, pkg);
  calculateQuaturlyPaymentTerm(payment_term, paymentTerms, pkg);
  calculateYearlyPaymentTerm(payment_term, paymentTerms, pkg);

  return paymentTerms;
};
export const calculateMonthlyPaymentTerm = (
  payment_term,
  paymentTerms,
  pkg
) => {
  if (payment_term.is_monthly_selected) {
    if (payment_term.is_upfront) {
      if (payment_term.upfront_percentage > 0) {
        const totalUnFrontAmount =
          payment_term.total_amount * (payment_term.upfront_percentage / 100);
        paymentTerms.push({
          paymentTermKey: "is_monthly_selected",
          value: `$ ${formatCurrency.format(
            Math.round(totalUnFrontAmount)
          )} Upfront+ $ ${formatCurrency.format(
            Math.round((payment_term.total_amount - totalUnFrontAmount) / 12)
          )} / month`,
          simpleValue: "(monthly)",
          order_number: payment_term.monthly_position,
          pkg,
        });
      } else if (payment_term.upfront_amount > 0) {
        paymentTerms.push({
          paymentTermKey: "is_monthly_selected",
          value: `$ ${formatCurrency.format(
            Math.round(payment_term.upfront_amount)
          )} Upfront+ $ ${formatCurrency.format(
            Math.round(
              (payment_term.total_amount - payment_term.upfront_amount) / 12
            )
          )} / month`,
          simpleValue: "(monthly)",
          order_number: payment_term.monthly_position,
          pkg,
        });
      }
    } else {
      paymentTerms.push({
        paymentTermKey: "is_monthly_selected",
        value: `$ ${formatCurrency.format(
          Math.round(payment_term.total_amount / 12)
        )} / month`,
        simpleValue: "(monthly)",
        order_number: payment_term.monthly_position,
        pkg,
      });
    }
  }
};
export const calculateQuaturlyPaymentTerm = (
  payment_term,
  paymentTerms,
  pkg
) => {
  if (payment_term.is_quarterly_selected) {
    if (payment_term.is_upfront) {
      if (payment_term.upfront_percentage > 0) {
        const totalUnFrontAmount =
          payment_term.total_amount * (payment_term.upfront_percentage / 100);
        paymentTerms.push({
          paymentTermKey: "is_quarterly_selected",
          value: `$ ${formatCurrency.format(
            Math.round(totalUnFrontAmount)
          )} Upfront+ $ ${formatCurrency.format(
            Math.round((payment_term.total_amount - totalUnFrontAmount) / 4)
          )} / quarter`,
          simpleValue: "(quarterly)",
          order_number: payment_term.quarterly_position,
          pkg,
        });
      } else if (payment_term.upfront_amount > 0) {
        paymentTerms.push({
          paymentTermKey: "is_quarterly_selected",
          value: `$ ${formatCurrency.format(
            Math.round(payment_term.upfront_amount)
          )} Upfront+ $ ${formatCurrency.format(
            Math.round(
              (payment_term.total_amount - payment_term.upfront_amount) / 4
            )
          )} / quarter`,
          simpleValue: "(quarterly)",
          order_number: payment_term.quarterly_position,
          pkg,
        });
      }
    } else {
      paymentTerms.push({
        paymentTermKey: "is_quarterly_selected",
        value: `$ ${formatCurrency.format(
          Math.round(payment_term.total_amount / 4)
        )} / quarter`,
        simpleValue: "(quarterly)",
        order_number: payment_term.quarterly_position,
        pkg,
      });
    }
  }
};
export const calculateYearlyPaymentTerm = (payment_term, paymentTerms, pkg) => {
  if (payment_term.is_yearly_selected) {
    if (payment_term.is_upfront) {
      if (payment_term.upfront_percentage > 0) {
        const totalUnFrontAmount =
          payment_term.total_amount * (payment_term.upfront_percentage / 100);
        paymentTerms.push({
          paymentTermKey: "is_yearly_selected",
          value: `$ ${formatCurrency.format(
            Math.round(totalUnFrontAmount)
          )} Upfront+ $ ${formatCurrency.format(
            Math.round(payment_term.total_amount - totalUnFrontAmount)
          )} / yearly`,
          simpleValue: "(yearly)",
          order_number: payment_term.yearly_position,
          pkg,
        });
      } else if (payment_term.upfront_amount > 0) {
        paymentTerms.push({
          paymentTermKey: "is_yearly_selected",
          value: `$ ${formatCurrency.format(
            Math.round(payment_term.upfront_amount)
          )} Upfront+ ${formatCurrency.format(
            Math.round(payment_term.total_amount - payment_term.upfront_amount)
          )} / yearly`,
          simpleValue: "(yearly)",
          order_number: payment_term.yearly_position,
          pkg,
        });
      }
    } else {
      paymentTerms.push({
        paymentTermKey: "is_yearly_selected",
        value: `$ ${formatCurrency.format(
          Math.round(payment_term.total_amount)
        )} / yearly`,
        simpleValue: "(yearly)",
        order_number: payment_term.yearly_position,
        pkg,
      });
    }
  }
};

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  selectedSurvey: null | number;
  questionList: [];
  packageList: [];
  accountId: any;
  clientId: any;
  surveyList: [];
  surveyId: number | any;
  taxFillingStatus: string;
  noOfStateIncomeEarnIn: any;
  current_account_system: string;
  selectedPackageForSurvey: null;
  dont_allow_to_change_package_id: boolean;
  proposals: [];
  roadMap: any;
  paymentTermsForRoadmap: [];
  clientInfo : any;
  isDataAvailable : boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ValueRoadmapController extends BlockComponent<
  Props,
  S,
  SS
> {
  getSurveyListByClientIdInValueRoadmapApiCallId: string = "";
  getValueRoadMapApiCallId: string = "";
  getAllQuestionbySurveyTypeApiCallId: string = "";
  getClientInfoApiCallId : string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      selectedSurvey: 1,
      questionList: [],
      packageList: [],
      accountId: null,
      clientId: null,
      surveyList: [],
      surveyId: null,
      taxFillingStatus: "",
      noOfStateIncomeEarnIn: 1,
      current_account_system: "",
      selectedPackageForSurvey: null,
      dont_allow_to_change_package_id: false,
      proposals: [],
      roadMap: null,
      paymentTermsForRoadmap: [],
      clientInfo : {},
      isDataAvailable : false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  handleAPiErrorResponse = (responseJson) => {
    if (typeof responseJson?.errors === "string") {
      this.props.showToast({
        type: "error",
        message: responseJson.errors,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };
  handleDropDown = () => {
    return this.state.proposals.map((proposal) => {
      return { ...proposal, type: "roadmap" };
    });
  };
  getSelectedSurvey = (responseJson) => {
    let selectedSurvey = null;
    if (this.state.roadMapId) {
      return selectedSurvey;
    } else if (responseJson.data.length > 0) {
      return (
        responseJson.data[0].id +
        "_" +
        responseJson.data[0].attributes.about_us.data.attributes.survey_type
      );
    }
  };
  handleSurveyListResponse = (responseJson) => {
    this.props.hideLoader();
    const surveyType =
      responseJson.data.length > 0
        ? responseJson.data[0].attributes.about_us.data.attributes.survey_type
        : "individual";
    const selectedSurvey = this.getSelectedSurvey(responseJson);

    if (responseJson.data) {
      this.setState(
        {
          surveyList:
            this.state.proposals.length > 0
              ? [...responseJson.data, ...this.handleDropDown()]
              : responseJson.data,
        },
        () => {
          if (selectedSurvey) {
            this.selectSurvey(selectedSurvey, surveyType);
          } else if (
            !selectedSurvey &&
            this.state.surveyList.length > 0 &&
            (this.state.surveyList[0].type == "roadmap" || this.state.roadMapId)
          ) {
            this.selectRoadmap();
          }
        }
      );
    }
  };
  selectSurvey = (selectedSurvey, surveyType) => {
    this.setState(
      {
        selectedSurvey: this.state.surveyId
          ? this.state.surveyId + "_" + this.state.surveyType
          : selectedSurvey,
        selectedSurveyType: this.state.surveyType
          ? this.state.surveyType
          : surveyType,
      },
      () => {
        this.state.selectedSurvey &&
          this.getAllQuestionbySurveyType(this.state.selectedSurvey);
      }
    );
  };
  selectRoadmap = () => {
    this.setState(
      {
        selectedSurvey: this.state.roadMapId
          ? this.state.roadMapId + "_" + "roadmap"
          : this.state.surveyList[0].id + "_" + "roadmap",
        selectedSurveyType: "roadmap",
      },
      () => {
        const id = this.state.roadMapId || this.state.surveyList[0].id;
        this.getRoadMapForValueRoadmap(id);
      }
    );
  };
  handleQuestionsListResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson.data) {
      const sortedObjs = _.sortBy(responseJson?.data, "id");
      this.setState(
        {
          questionListtemp: sortedObjs,
        },
        () => {
          this.getValueRoadMapBySurveyId();
        }
      );
    } else {
      this.handleAPiErrorResponse(responseJson);
    }
  };
  setCarrierNeeds = (data, questionList) => {
    if (
      data?.attributes?.career_need?.data?.attributes &&
      data.attributes.about_us.data.attributes.survey_type === "individual"
    ) {
      const questionListData = this.state.questionListtemp;
      let temp = questionListData.filter((question) => {
        return (
          data.attributes.career_need.data.attributes.title_step ===
          question.attributes.title_step
        );
      })[0];
      const options = temp.attributes.options.data.map((option) => {
        return {
          ...option,
          isSelected:
            data?.attributes?.career_need?.data?.attributes.answer.indexOf(
              option.id
            ) > -1
              ? true
              : false,
        };
      });
      temp.attributes.options.data = options;
      questionList.push({ ...temp, id: +temp.id });
    }
  };
  setCashflow = (data, questionList) => {
    if (data?.attributes?.cash_flow?.data?.attributes) {
      const questionListData = this.state.questionListtemp;
      let temp = questionListData.filter((question) => {
        return (
          data.attributes.cash_flow.data.attributes.title_step ===
          question.attributes.title_step
        );
      })[0];
      const options = temp?.attributes?.options?.data?.map((option) => {
        return {
          ...option,
          isSelected:
            data?.attributes?.cash_flow?.data?.attributes.answer.indexOf(
              option.id
            ) > -1
              ? true
              : false,
        };
      });
      temp.attributes.options.data = options;
      questionList.push({ ...temp, id: +temp.id });
    }
  };
  setReporting = (data, questionList) => {
    if (data?.attributes?.reporting?.data?.attributes) {
      const questionListData = this.state.questionListtemp;
      let temp = questionListData.filter((question) => {
        return (
          data.attributes.reporting.data.attributes.title_step ===
          question.attributes.title_step
        );
      })[0];
      const options = temp?.attributes?.options?.data?.map((option) => {
        return {
          ...option,
          isSelected:
            data?.attributes?.reporting?.data?.attributes.answer.indexOf(
              option.id
            ) > -1
              ? true
              : false,
        };
      });
      temp.attributes.options.data = options;
      questionList.push({ ...temp, id: +temp.id });
    }
  };
  setSaving = (data, questionList) => {
    if (data?.attributes?.saving?.data?.attributes) {
      const questionListData = this.state.questionListtemp;
      let temp = questionListData.filter((question) => {
        return (
          data.attributes.saving.data.attributes.title_step ===
          question.attributes.title_step
        );
      })[0];
      const options = temp?.attributes?.options?.data?.map((option) => {
        return {
          ...option,
          isSelected:
            data?.attributes?.saving?.data?.attributes.answer.indexOf(
              option.id
            ) > -1
              ? true
              : false,
        };
      });
      temp.attributes.options.data = options;
      questionList.push({ ...temp, id: +temp.id });
    }
  };
  setTaxSaving = (data, questionList) => {
    if (data?.attributes?.tax_saving?.data?.attributes) {
      const questionListData = this.state.questionListtemp;
      let temp = questionListData.filter((question) => {
        return (
          data.attributes.tax_saving.data.attributes.title_step ===
          question.attributes.title_step
        );
      })[0];
      const options = temp?.attributes?.options?.data?.map((option) => {
        return {
          ...option,
          isSelected:
            data?.attributes?.tax_saving?.data?.attributes.answer.indexOf(
              option.id
            ) > -1
              ? true
              : false,
        };
      });
      temp.attributes.options.data = options;
      questionList.push({ ...temp, id: +temp.id });
    }
  };
  setBusinessflowQuestions = (remainIngQuestions, data) => {
    remainIngQuestions.push({
      attributes: {
        description:
          " In order to show you the right feature options, we need a few more details about your business",
        options: [
          {
            name: "Industry",
            key: "industryType",
            value: data?.attributes?.about_us?.data?.attributes?.industry,
          },
          {
            name: "Current accounting system",
            key: "currentAccountingSystem",
            value:
              data?.attributes?.about_us?.data?.attributes
                ?.current_account_system,
          },
          {
            name: "Annual Revenue",
            key: "annualRevenue",
            value: data?.attributes?.about_us?.data?.attributes?.annual_revenue,
          },
          {
            name: "Other Business partner",
            key: "otherBusinessPartner",
            value:
              data?.attributes?.about_us?.data?.attributes
                ?.other_business_partners,
          },
          {
            name: "Current entity structure",
            key: "currentEntityStructure",
            value:
              data?.attributes?.about_us?.data?.attributes
                ?.current_entity_structure,
          },
          {
            name: "Number of Employees",
            key: "noOfEmployee",
            value:
              data?.attributes?.about_us?.data?.attributes?.number_of_employee,
          },
          {
            name: "Current Payroll System",
            key: "currentPayRollSystem",
            value:
              data?.attributes?.about_us?.data?.attributes?.current_payroll,
          },
          {
            name: "I want to manage my payroll",
            key: "isManagePayroll",
            value: data?.attributes?.about_us?.data?.attributes
              ?.manage_my_payroll
              ? "Yes"
              : "No",
          },
        ],
        stepType: "BusinessDetail",
      },
      type: "customQuestion",
    });
    remainIngQuestions.push({
      attributes: {
        description:
          " In order to show you the right feature options, we need a few more details about your business",
        stepType: "Accounting",
      },
      type: "customQuestion",
    });
    this.setQuesion(remainIngQuestions, data);
  };
  setQuesion = (remainIngQuestions, data) => {
    remainIngQuestions.push({
      attributes: {
        description: "Tell us about your most recent tax filling",
        options: [
          {
            name: "I own real estate outside of my primary residnce",
            key: "isRealStateBusinessOutSideResidense",
            value:
              data?.attributes?.about_us?.data?.attributes?.own_real_estate,
          },
          {
            name: "I actively trade investments on a monthly basis",
            key: "isTradeOnMonthlyBasis",
            value:
              data?.attributes?.about_us?.data?.attributes?.trade_investments,
          },
          {
            name: "I own/ trade cryptocurrencies",
            key: "isTradeCrypto",
            value:
              data?.attributes?.about_us?.data?.attributes
                ?.trade_cryptocurrence,
          },
          {
            name: "I have ownership in a business that is not my own",
            key: "isOwnerShipInAnotherBusiness",
            value:
              data?.attributes?.about_us?.data?.attributes
                ?.ownership_in_business,
          },
          {
            name: "I earn income in multiple states and/or countries",
            key: "isIncomeInAnotherStateOrCountary",
            value:
              data?.attributes?.about_us?.data?.attributes
                ?.income_in_multiple_state,
          },
          {
            name: "I have an active issues with the IRS",
            key: "isActiveIssueWithIRS",
            value:
              data?.attributes?.about_us?.data?.attributes?.active_issue_irs,
          },
          {
            name: "All my income is earned in 1 state",
            key: "isAllIncomeinOneState",
            value:
              data?.attributes?.about_us?.data?.attributes?.earned_in_one_state,
          },
        ],
        stepType: "TaxFilling",
      },
      type: "customQuestion",
    });
  };
  setIndividualFlowQuestion = (step1Answer, data, remainIngQuestions) => {
    if (step1Answer) {
      if (
        step1Answer?.attributes?.description ===
        "I am an existing client of {Practice}"
      ) {
        this.setQuesion(remainIngQuestions, data);
      } else {
        remainIngQuestions.push({
          attributes: {
            description: "What is your Annual Household Income?",
            value:
              data?.attributes?.about_us?.data?.attributes
                ?.annual_house_hold_income,
            stepType: "AnnualIncome",
          },
          type: "customQuestion",
        });

        this.setQuesion(remainIngQuestions, data);
      }
    }
  };
  setAboutUsSteps = (data, questionListData, questionList) => {
    data.attributes.about_us.data.attributes.answer.forEach((step) => {
      let temp = questionListData.filter((question) => {
        return step.title_step == question.attributes.title_step;
      })[0];
      const options = temp?.attributes?.options?.data?.map((option) => {
        return {
          ...option,
          isSelected: option.id == step.answer_id ? true : false,
        };
      });
      temp.attributes.options.data = options;
      questionList.push({ ...temp, id: +temp.id });
    });
  };
  getStep1Answer = (data, questionListData) => {
    const selectedStepNo1 = data.attributes.about_us.data.attributes.answer.filter(
      (op) => {
        return op.title_step == 1;
      }
    )[0];

    let step1 = questionListData.filter((question) => {
      return question?.attributes?.title_step == selectedStepNo1?.title_step;
    })[0];
    const step1Answer = step1?.attributes?.options?.data?.find((sp) => {
      return sp.id == selectedStepNo1?.answer_id;
    });
    return step1Answer;
  };

  setBusinessNeed = (data, questionList) => {
    if (
      data?.attributes?.business_need?.data?.attributes &&
      data.attributes.about_us.data.attributes.survey_type !== "individual"
    ) {
      const questionListData = this.state.questionListtemp;
      let temp = questionListData.filter((question) => {
        return (
          data.attributes.business_need.data.attributes.title_step ===
          question.attributes.title_step
        );
      })[0];
      const options = temp?.attributes?.options?.data?.map((option) => {
        return {
          ...option,
          isSelected:
            data?.attributes?.business_need?.data?.attributes.answer.indexOf(
              option.id
            ) > -1
              ? true
              : false,
        };
      });
      temp.attributes.options.data = options;
      questionList.push({ ...temp, id: +temp.id });
    }
  };

  handelValueRoadMapResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson.data) {
      const data = responseJson.data;
      const questionList = [];
      const remainIngQuestions = [];
      if (data.attributes?.about_us?.data?.attributes) {
        const questionListData = this.state.questionListtemp;
        const step1Answer = this.getStep1Answer(data, questionListData);
        if (this.state.selectedSurveyType == "individual") {
          this.setIndividualFlowQuestion(step1Answer, data, remainIngQuestions);
        } else {
          this.setBusinessflowQuestions(remainIngQuestions, data);
        }
        this.setAboutUsSteps(data, questionListData, questionList);
      }
      this.setAllSteps(data, questionList);
      this.setSurvey(questionList, remainIngQuestions, responseJson, data);
    } else {
      this.handleAPiErrorResponse(responseJson);
    }
  };
  setAllSteps = (data, questionList) => {
    this.setBusinessNeed(data, questionList);
    this.setCarrierNeeds(data, questionList);
    this.setCashflow(data, questionList);
    this.setReporting(data, questionList);
    this.setSaving(data, questionList);
    this.setTaxSaving(data, questionList);
  };
  setSurvey = (questionList, remainIngQuestions, responseJson, data) => {
    const packagelist = responseJson?.data?.attributes?.packages || [];
    let selectedPackageForSurvey = packagelist.find((pkg) => {
      return pkg.id == responseJson?.data?.attributes?.payment?.package_id;
    });
    if (!selectedPackageForSurvey) {
      selectedPackageForSurvey = packagelist.find((pkg) => {
        return (
          pkg.id ==
          responseJson?.data?.attributes?.onboarding_survey
            ?.package_management_id
        );
      });
    }
    if (!selectedPackageForSurvey) {
      selectedPackageForSurvey = packagelist.find((pkg) => {
        return (
          pkg.id == responseJson?.data?.attributes?.recurring_charge?.package_id
        );
      });
    }
    let sortedObjs = _.sortBy(questionList, "id");
    sortedObjs.splice(3, remainIngQuestions.length, ...remainIngQuestions);
    this.setState(
      {
        roadMap: null,
        survey_status: responseJson?.data?.attributes?.survey_status,
        questionList: sortedObjs,
        packageList: packagelist,
        date: responseJson?.data?.attributes?.created_at,
        taxFillingStatus: data?.attributes?.about_us?.data?.attributes?.status,
        noOfStateIncomeEarnIn:
          data?.attributes?.about_us?.data?.attributes?.no_of_states_earn,
        current_account_system:
          data?.attributes?.about_us?.data?.attributes?.current_account_system,
        isHelpNeededinAccounting:
          data?.attributes?.about_us?.data?.attributes?.setting_up_accounting ==
          "Yes"
            ? true
            : false,
        noOFBankAccount:
          data?.attributes?.about_us?.data?.attributes?.no_of_credit_card,
        isManageInvoice:
          data?.attributes?.about_us?.data?.attributes?.sending_invoice == "Yes"
            ? true
            : false,
        noOFInvoice:
          data?.attributes?.about_us?.data?.attributes?.no_of_invoice,
        isBalanceSheetAccurate:
          data?.attributes?.about_us?.data?.attributes
            ?.balance_sheet_accounting == "Yes"
            ? true
            : false,
        noOfmonthForBalanceSheet:
          data?.attributes?.about_us?.data?.attributes?.no_of_accounting,
      },
      () => {
        this.setState(
          {
            selectedPackageForSurvey: selectedPackageForSurvey,
            isSelectedByClient: selectedPackageForSurvey?.id ? true : false,
          },
          () => {
            this.setState({
              dont_allow_to_change_package_id: this.state.isSelectedByClient
                ? true
                : false,
            });
          }
        );
      }
    );
  };

  handleSingleClient = (responseJson) => {
    this.props.hideLoader();
    this.getClientInfo(responseJson.data.attributes.document_templates[0].survey_id)
    if (responseJson?.data && responseJson?.data?.attributes) {
      this.setState(
        {
          singleClient: responseJson?.data?.attributes,
          proposals: responseJson?.data?.attributes?.proposals,
        },
        () => {
          this.getSurveyListByClientIdInValueRoadmap();
        }
      );
    } else {
      this.handleAPiErrorResponse(responseJson);
    }
  };
  getTotalPackageWithPackageTerms = () => {
    const totalPackageWithPackageTerms = [];
    for (const pkg of this.state.roadMap.attributes.custom_packages.data) {
      let paymentTemrs1 = processPaymentTerms({ data: pkg });
      paymentTemrs1.forEach((paymentterm: any) => {
        if (!paymentterm.id) {
          totalPackageWithPackageTerms.push({
            ...pkg,
            attributes: {
              ...pkg.attributes,
              selectedPaymentTerm: {
                ...paymentterm,
                id: pkg.id,
              },
            },
          });
        }
      });
    }
    return totalPackageWithPackageTerms;
  };

  handelRoadMapResponse = async (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.id) {
      localStorage.setItem(
        "primaryColor",
        responseJson?.data?.attributes?.add_primary_color
      );
      localStorage.setItem(
        "secondaryColor",
        responseJson?.data?.attributes?.add_secondary_color
      );
      this.setState(
        {
          roadMap: responseJson?.data,
        },
        () => {
          const totalPackageWithPackageTerms = this.getTotalPackageWithPackageTerms();
          const selectedPackageForRoadMap = totalPackageWithPackageTerms.find(
            (pkg) => {
              const paymentTerm = this.state.roadMap?.attributes?.obs
                ?.payment_term;
              return (
                pkg.attributes.selectedPaymentTerm.paymentTermKey +
                  "_" +
                  pkg.attributes.selectedPaymentTerm.id ==
                paymentTerm +
                  "_" +
                  this.state.roadMap?.attributes?.obs
                    ?.custom_package_management_id
              );
            }
          );
          if (selectedPackageForRoadMap) {
            if (selectedPackageForRoadMap.id) {
              this.setState(
                {
                  paymentTermsForRoadmap: totalPackageWithPackageTerms,
                  selectedPackageForSurvey: selectedPackageForRoadMap,
                  isSelectedByClient: !!selectedPackageForRoadMap.id,
                },
                () => {
                  this.setState({
                    dont_allow_to_change_package_id: !!this.state
                      .isSelectedByClient,
                  });
                }
              );
            }
          } else {
            this.setState({
              paymentTermsForRoadmap: totalPackageWithPackageTerms,
            });
          }
        }
      );
    } else {
      this.handleAPiErrorResponse(responseJson);
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.getClientDetailApiCallId === apiRequestCallId) {
        this.handleSingleClient(responseJson);
      } else if (
        apiRequestCallId === this.getSurveyListByClientIdInValueRoadmapApiCallId
      ) {
        this.handleSurveyListResponse(responseJson);
      } else if (this.getValueRoadMapApiCallId === apiRequestCallId) {
        this.handelValueRoadMapResponse(responseJson);
      } else if (
        this.getAllQuestionbySurveyTypeApiCallId === apiRequestCallId
      ) {
        this.handleQuestionsListResponse(responseJson);
      } else if(this.getClientInfoApiCallId === apiRequestCallId){
        this.handleClientInfo(responseJson)
      } else if (
        this.createChecklistforSelectedPackageValueRoadmapApiCallId ==
        apiRequestCallId
      ) {
        this.handleCreateChecklist(responseJson);
      } else if (this.getRoadMapForValueRoadmapAPiCallId == apiRequestCallId) {
        this.handelRoadMapResponse(responseJson);
      } else if (
        this.createChecklistforSelectedPackageValueRoadmapInRoadmapApiCallId ==
        apiRequestCallId
      ) {
        this.handleCreateChecklistForRoadmap(responseJson);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      await this.props.showAlert({
        title: "Error",
        message: AlertBodyMessage,
      });
      if (
        AlertBodyMessage === "Session TimeOut,Please Login Again." ||
        AlertBodyMessage === "User Account Not Found"
      ) {
        await StorageProvider.remove("authToken");
        await StorageProvider.remove("accountId");
        this.props.history.push("/");
      }
    }
  }
  handleCreateChecklist = async (responseJson) => {
    this.props.hideLoader();
    await StorageProvider.set(
      "package_management_id",
      this.state.selectedPackageForSurvey?.id
    );
    setTimeout(() => {
      this.props.history.push({
        pathname: "/home/clientsList/details/onboardingCustomization",
        state: {
          surveyId: this.state.selectedSurvey?.split("_")[0],
          allow_to_change_package_id: !this.state
            .dont_allow_to_change_package_id,
          survey_status: this.state.survey_status,
        },
      });
    }, 500);
  };
  handleCreateChecklistForRoadmap = async (responseJson) => {
    this.props.hideLoader();
    if (this.state.selectedPackageForSurvey) {
      await StorageProvider.set("package_management_id", "");
      await StorageProvider.set(
        "custom_package_management_id",
        this.state.selectedPackageForSurvey.id
      );
      setTimeout(() => {
        this.props.history.push({
          pathname: "/home/clientsList/details/onboardingCustomization",
          state: {
            road_customization_id: this.state.selectedPackageForSurvey
              .attributes.road_customization_id,
            custom_package_management_id: this.state.selectedPackageForSurvey
              .id,
            allow_to_change_package_id: false,
            survey_status: this.state.survey_status,
            selected_payment_term: this.state.selectedPackageForSurvey
              .attributes.selectedPaymentTerm.paymentTermKey,
            parent_package_type: this.state.selectedPackageForSurvey.attributes
              .parent_package_type,
          },
        });
      }, 1000);
    }
  };

  getSurveyListByClientIdInValueRoadmap = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSurveyListByClientIdInValueRoadmapApiCallId =
      requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_survey/surveys?client_id=${this.state.clientId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getValueRoadMapBySurveyId = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getValueRoadMapApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_survey/surveys/${this.state.selectedSurvey?.split("_")[0]}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllQuestionbySurveyType = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllQuestionbySurveyTypeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_survey/questions?questions[q_type]=${this.state.selectedSurveyType}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getClientDetail = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getClientDetailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getClientDetail}/${this.state.clientId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getClientInfo = (surveyd) =>{

    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getClientInfoApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_client_dashboard/last_doc_sign_details?survey_id=${surveyd}&client_id=${this.state.clientId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleClientInfo = (apiResponse) =>{
    if (apiResponse.data.length > 0) {
      this.setState({ clientInfo: apiResponse.data[0], isDataAvailable: true });
    } else {
      this.setState({ isDataAvailable: false });
    }
  }
  

  createChecklistforSelectedPackageValueRoadmap = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createChecklistforSelectedPackageValueRoadmapApiCallId =
      requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_informations/${this.state.clientId}/create_checklists`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        package_management_id: this.state.selectedPackageForSurvey?.id,
        survey_id: this.state.selectedSurvey?.split("_")[0],
        account_id : this.state.accountId,
        accountId : this.state.accountId,
        id: this.state.clientId,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createChecklistforSelectedPackageValueRoadmapInRoadmap = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createChecklistforSelectedPackageValueRoadmapInRoadmapApiCallId =
      requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_informations/${this.state.clientId}/create_checklists_for_roadmap`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        custotom_package_id: this.state.selectedPackageForSurvey?.id,
        roadmap_id: this.state.selectedPackageForSurvey?.attributes
          ?.road_customization_id,
        parent_package_type: this.state.selectedPackageForSurvey?.attributes
          ?.parent_package_type,
        account_id: +this.state.accountId,
        id: this.state.clientId,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getRoadMapForValueRoadmap = (id: any) => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRoadMapForValueRoadmapAPiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRoadmap}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
