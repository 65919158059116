//@ts-nocheck

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { DialogProps } from "../../../components/src/DialogContext";
import { DEFAULT_PERMISSIONS_ARRAY } from "../../../components/src/PermissionsContext";

export const configJSON = require("./config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: any;
  accountId: any;
  roadmap: any;
  defaultRoadmap: any;
  defaultLogoImageFromServer: any;
  activeSideLink: string;
  anchorElement: any;
  selectedMenuItemIndex: number;
  isPlanChangedForNextCycle: boolean;
  isSubscriptionAlreadyCanceled: boolean;
  currentSubscription: any;
  currentUser: any;
  openModal: boolean;
  searchValue: string;
  hasUpdated: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class NewUserDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProfileApiCallId: string = "";
  createNewRoadmapApiCallId: string = "";
  getCurrentSubscriptionApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.ProfilePictureUpdatedMsg),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      accountId: "",
      roadmap: {},
      defaultRoadmap: {},
      defaultLogoImageFromServer: "",
      activeSideLink: "",
      anchorElement: null,
      selectedMenuItemIndex: null,
      isPlanChangedForNextCycle: false,
      isSubscriptionAlreadyCanceled: false,
      currentSubscription: null,
      currentUser: null,
      openModal: true,
      searchValue: "",
      hasUpdated: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleCreateRoadmapResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.data) {
      this.props.history.push({
        pathname: "/home/roadmaps/createRoadMap",
        state: {
          roadmap: responseJson?.data,
          defaultRoadmap: this.state.defaultRoadmap,
        },
      });
    } else {
      this.props.showToast({ type: "error", message: "Some Error Occured" });
      this.handleApiErrorResponse(responseJson);
    }
  };

  handleCurrentSubsResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data && responseJson?.data.length > 0) {
      this.setState({
        subscribed: true,
        isPlanChangedForNextCycle:
          responseJson?.data[0].attributes.scheduled_subscription_status ===
          "active" &&
          new Date(
            responseJson?.data[0].attributes?.scheduled_subscription_date
          ).getTime() > new Date().getTime(),
        isSubscriptionAlreadyCanceled:
          responseJson?.data[0]?.attributes?.sub_canceled_at &&
          responseJson?.data[0]?.attributes?.sub_cancel_at &&
          new Date(responseJson?.data[0]?.attributes?.sub_cancel_at).getTime() >
          new Date(
            responseJson?.data[0]?.attributes?.sub_canceled_at
          ).getTime(),
        currentSubscription: responseJson?.data?.length
          ? responseJson?.data[0].attributes
          : null,
      });
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };

  handleApiErrorResponse = (responseJson) => {
    if (typeof responseJson?.errors === "string") {
      this.props.showToast({
        type: "error",
        message: responseJson.errors,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  handleGetProfileResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.id) {
      this.setState(
        {
          user: responseJson?.data?.attributes,
        },
        async () => {
          await StorageProvider.set(
            "currentUser",
            JSON.stringify(responseJson)
          );
          this.setState(
            {
              currentUser: responseJson,
            },
            () => {
              if (
                responseJson?.data?.attributes?.employee_permission?.length >
                0 &&
                responseJson?.data?.attributes?.employee_name
              ) {
                this.formatAndSetPermissions(
                  responseJson?.data?.attributes?.employee_permission
                );
              } else {
                const allPermissions = DEFAULT_PERMISSIONS_ARRAY;
                localStorage.setItem(
                  "permissions",
                  JSON.stringify(allPermissions)
                );
                this.props.setPermissions(allPermissions);
              }
              this.getCurrentSubscription();
            }
          );
        }
      );
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };

  formatAndSetPermissions = async (permissions: string[]) => {
    let simplePermissionsArray: string[] = [];
    permissions.forEach((category: any) => {
      category?.permissions.forEach((per: any) => {
        if (per?.is_permitted) {
          simplePermissionsArray.push(`${per.permission}-${per.category}`);
        }
      });
    });
    localStorage.setItem("permissions", JSON.stringify(simplePermissionsArray));
    this.props.setPermissions(simplePermissionsArray);
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getProfileApiCallId) {
        this.handleGetProfileResponse(responseJson);
      } else if (apiRequestCallId === this.createNewRoadmapApiCallId) {
        this.handleCreateRoadmapResponse(responseJson);
      } else if (apiRequestCallId === this.getCurrentSubscriptionApiCallId) {
        this.handleCurrentSubsResponse(responseJson);
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({
        title: "Error",
        message: AlertBodyMessage,
      });
      if (
        msg === 'Session TimeOut,Please Login Again.' || msg === 'User Account Not Found' ||
        msg === "Record not found"
      ) {
        await StorageProvider.remove("authToken");
        this.props.history.push("/");
      }
    } else if (getName(MessageEnum.ProfilePictureUpdatedMsg) === message.id) {
      const profilePicUrl = message.getData(
        getName(MessageEnum.ProfilePictureUpdateData)
      );
      this.setState({
        currentUser: {
          ...this.state.currentUser,
          data: {
            ...this.state.currentUser.data,
            attributes: {
              ...this.state.currentUser.data.attributes,
              [profilePicUrl.type]: profilePicUrl.imageUrl
            }
          }
        }
      });
    }
  }

  createNewRoadmap = (values: any) => {
    this.props.showLoader();
    const headers = {
      // "Content-Type": configJSON.ApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNewRoadmapApiCallId = requestMessage.messageId;

    let formData = new FormData();

    formData.append("add_primary_color", "");
    formData.append("add_secondary_color", "");
    formData.append("phone_number", "");
    formData.append("web_url", "");
    formData.append("custom_button_link", "");
    formData.append("account_id", this.state.accountId);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.roadmapEndpoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getclmail = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_informations/get_client_email?client_id=10878`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  clientapi = () => {
    const header = {
      "content-type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_informations/get_client_email?client_id=11897`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
 
  getCurrentSubscription = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
      skipSubscriptionCheck: true,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCurrentSubscriptionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_subscriptions/subscriptions`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getProfile = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
      skipSubscriptionCheck: true,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${"account_block/accounts"}/${this.state.accountId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  searchClient = (value) => {
    if (this.props.history.location.pathname == "/home/clientsList") {
      const requestMessage = new Message(getName(MessageEnum.SearchClientMsg));
      requestMessage.addData(
        getName(MessageEnum.SearchClientMsgData),
        this.state.searchValue || value
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.props.history.push({
        pathname: "/home/clientsList",
        state: { searchValue: this.state.searchValue || value },
      });
    }
  };
}
