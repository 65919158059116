//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import PhaseTwoField from "../../../../components/src/PhaseTwoField.web";
import { Formik, Form } from "formik";
import * as Yup from "yup";
const Schema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be in valid format.")
    .required("This field is required."),
});

export class ConfirmEmailDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  submitForm = async (values) => {
    const { checked } = this.props.dataTopass;
   
    if (checked) {
        this.props.onSubmit(values);
    }
    else {
      this.closeDialog({
        email: values.email,
      });
    }
  }
  handleEmailChange = (handleChange, event) => {
    handleChange(event);
  }

  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Schema}
        validateOnMount={true}
        onSubmit={this.submitForm.bind(this)}
      >
        {(formikProps) => {
          const {
            values,
            handleChange,
            errors,
            isValid,
            touched,
          } = formikProps;
          return (
            <Form autoComplete="off" noValidate style={{ margin: 0 }}>
              <Grid container style={{ width: "30vw" }} justifyContent="center">
                <Grid item xs={12} container justifyContent="flex-end">
                  {!this.props.dataTopass?.checked ?
                    (<CloseIcon
                      onClick={this.closeDialog.bind(this, "")}
                      className={classes.closeIcon}
                    />) : ("")}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h3"
                    color="primary"
                    className={classes.confirmEmaildialogHeading}
                  >
                    Confirm Your Email
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <PhaseTwoField
                    placeholder="Enter Email"
                    id="confirmEmail"
                    type="email"
                    name="email"
                    color="primary"
                    value={values.email}
                    onChange={this.handleEmailChange.bind(this, handleChange)}
                    error={errors.email && touched.email}
                    helperText={
                      errors.email && touched.email ? errors.email : ""
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!isValid}
                    className={classes.confirmButton}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const ConfirmEmailDialogWithRouter = withRouter(ConfirmEmailDialog);
const ConfirmEmailDialogWithToast = withToast(ConfirmEmailDialogWithRouter);
const ConfirmEmailDialogWithLoader = withLoader(ConfirmEmailDialogWithToast);
const ConfirmEmailDialogWithAlertBox = withAlertBox(
  ConfirmEmailDialogWithLoader
);
const ConfirmEmailDialogWithDialogBox = withDialogBox(
  ConfirmEmailDialogWithAlertBox
);

export default withStyles((theme) =>
  createStyles({
    confirmEmaildialogHeading: {
      fontSize: "2rem",
      fontWeight: "bold",
      margin: "1.5rem 0",
      textAlign: "center",
    },
    confirmButton: {
      margin: "1.5rem 0px",
      width: "40%",
    },
    closeIcon: {
      cursor: "pointer",
      fontSize: "1.5rem",
      margin: "1rem",
    },
  })
)(ConfirmEmailDialogWithDialogBox);
