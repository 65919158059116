//@ts-nocheck
// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
export const configJSON = require("../config");
import { client } from "../../../../components/src/HelloSignComponent.web";
import moment from "moment";
import { themeProps } from "../../../../web/src/theme2";
import { setTimeout } from "timers";

import {
  calculateMonthlyPaymentTerm,
  calculateQuaturlyPaymentTerm,
  calculateYearlyPaymentTerm,
} from "../ClientList/ValueRoadmapController.web";
export const annual_packages = [
  "New Business Incubator",
  "IRS Rapid Relief Package",
  "Reporting-Only Package (Individual)",
  "Reporting-Only Package (Business)",
];
export const monthly_packages = [
  "Business Bundle",
  "Business Tax & Advisory",
  "Accounting Success Package",
  "Payroll Success Package",
  "Personal Bundle",
];
export type Props = RouterProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  paymentaccesstoken: string | null;
  accountId: null;
  clientId: null;
  cpaDetail: unknown;
  clientDetail: null;
  cfsSurveyId: number | string;
  package_management_id: number | string | null;
  packageList: any[];
  selectedPackage: unknown;
  signatureRequests: any[];
  cpaSurveySetting: any;
  currentStep: number;
  isLoadingPage: boolean;
  checkListDetail: any;
  selectedCheckListId: number;
  availableCheckList: any[];
  checkListStepperIndex: {};
  section_question_index: number;
  currentSection: {};
  currentQuestion: {};
  onBoadringSurveyChecklistAnswer: {};
  surveyPaymentStatus: boolean;
  surveySignatureStatus: boolean;
  allReadySignedRequests: [];
  completeStatus: number;
  totalQuestions: number;
  saved_for_later: boolean;
  theme: any;
  clientmail: string | null
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OnBoardingSurveylandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getSignatureRequestCallId: string;
  getCPADetialbyAccessTokenCallId: string;
  saveOnBoardingSurveyApiCallId: string;
  checkSurveyPaymentStatusApiCallId: string;
  updateClientStatusCallId: string;
  getEmbedUrlCallId: string;
  updateOnboardingSurveyStatusCallId: string;
  updateCheckListApiCallId: string;
  saveDocumentSignntureApiCallId: string;
  collectPaymentApiCallId: string;
  getsignDocsCallId: string;
  getSingleClientApiCallId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      paymentaccesstoken: null,
      accountId: null,
      clientId: null,
      cpaDetail: null,
      clientDetail: null,
      currentStep: null,
      signatureRequests: [],
      cfsSurveyId: null,
      package_management_id: null,
      packageList: [],
      cpaSurveySetting: null,
      selectedPackage: null,
      isLoadingPage: false,
      checkListDetail: null,
      selectedCheckListId: null,
      availableCheckList: [],
      checkListStepperIndex: {},
      section_question_index: 0,
      currentSection: {},
      currentQuestion: {},
      onBoadringSurveyChecklistAnswer: {},
      surveyPaymentStatus: false,
      surveySignatureStatus: false,
      allReadySignedRequests: [],
      completeStatus: 0,
      totalQuestions: 0,
      saved_for_later: false,
      theme: themeProps,
      clientmail: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  handleApiError = (responseJson) => {
    if (typeof responseJson?.errors === "string") {
      this.props.showToast({
        type: "error",
        message: "Some internal error",
      });
      this.setState({
        isEnable: true,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };
  handleSignatureRequestResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson.data && responseJson.data.data.length > 0) {
      const signatureRequests = [];
      responseJson.data.data.forEach((sign) => {
        const temp = {
          id: sign.id,
          document: sign.attributes.document,
          document_template_title: sign.attributes.document_template_title,
          status: sign.attributes.status,
          survey_id: sign.attributes.survey_id,
          document_template_id: sign.attributes.document_template_id
        };
        signatureRequests.push(temp);
      });
      this.setState({
        signatureRequests,
      });
    } else {
      this.handleApiError(responseJson);
    }

  };
  handleUpdateCheckListResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.message) {
      const totalQuestionCount = this.state.totalQuestions;
      let totalAnsweCount = 0;
      Object.keys(this.state.onBoadringSurveyChecklistAnswer).forEach(
        (questionKey) => {
          if (this.state.onBoadringSurveyChecklistAnswer[questionKey]?.answer) {
            totalAnsweCount++;
          }
        }
      );
      this.setState(
        {
          completeStatus: Math.round(
            (totalAnsweCount / totalQuestionCount) * 100
          ),
        },
        () => {
          this.getNextQuestion();
        }
      );
    } else {
      this.handleApiError(responseJson);
    }
  };
  setDocumentTitleAndIcon = () => {
    if (
      this.state.cpaDetail?.theme?.logo
    ) {
      const canvasOBS = document.createElement("canvas");
      canvasOBS.width = 160;
      canvasOBS.height = 160;
      const ctx = canvasOBS.getContext("2d");
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src =
        this.state.cpaDetail.theme.logo;
      img.onload = this.onImageLoadForOBS.bind(this, ctx, img, canvasOBS);

    }

    if (this.state.cpaDetail?.theme?.firm_name) {
      document.title = this.state.cpaDetail.theme.firm_name;
    }
  };
  onImageLoadForOBS = function (ctx, img, canvas) {
    ctx.drawImage(img, 0, 0);
    let linkOBS = document.querySelector("link[rel~='icon']");
    if (!linkOBS) {
      linkOBS = document.createElement("link");
      linkOBS.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(linkOBS);
    }
    linkOBS.href = canvas.toDataURL("image/x-icon");
  };
  handleCPADetialbyAccessTokenResponse = (responseJson) => {
    this.props.hideLoader();
    if (
      responseJson &&
      responseJson?.data &&
      responseJson?.data?.id &&
      responseJson?.data?.attributes
    ) {
      this.setState(
        {
          cpaDetail: responseJson?.data?.attributes,
          paymentaccesstoken:
            responseJson?.data?.attributes?.cpa_charge_access_token,
          cpaSurveySetting:
            responseJson?.data?.attributes?.custom_survey_setting?.data
              ?.attributes,
        },
        () => {
          this.setDocumentTitleAndIcon();
          this.saveOnBoardingSurvey();
          this.updateOnboardingSurveyStatus("Signature & Payment Pending");
          this.setTheme(responseJson.data.attributes.theme);
        }
      );
    } else {
      this.handleApiError(responseJson);
    }
  };
  moveToPaymentStep = (responseJson, packageList, selectedPackage) => {
    this.setState(
      {
        currentStep: this.state?.cpaDetail?.cpa_charge_info ? 2 : 3,
        packageList: packageList,
        isLoadingPage: false,
        package_management_id: this.state.roadmap_id
          ? this.state.custom_package_id
          : responseJson.data.attributes.package?.data?.id,
        selectedPackage: selectedPackage,
        availableCheckList:
          responseJson?.data?.attributes?.checklists?.data || [],
      },
      () => {
        if (this.state.currentStep == 3) {
          const answer = this.getAnswerOfEachQuestion(
            responseJson.data.attributes.checklists?.data
          );
          this.setState(
            {
              onBoadringSurveyChecklistAnswer:
                answer?.onBoadringSurveyChecklistAnswer,
              completeStatus: Math.round(
                (answer?.totalAnswerCount / answer?.totalQuestionCount) * 100
              ),
              totalQuestions: answer?.totalQuestionCount,
            },
            () => {
              const availableCheckList = [];
              const checkListStepperIndex = {};
              this.state?.checkListDetail &&
                this.state?.checkListDetail.length > 0 &&
                this.state?.checkListDetail?.forEach((checklist) => {
                  availableCheckList.push({ ...checklist });
                  checkListStepperIndex[checklist?.id] = checkListStepperIndex[
                    checklist?.id
                  ]
                    ? checkListStepperIndex[checklist?.id]
                    : 0;
                });
              this.setState({
                availableCheckList: availableCheckList,
                checkListStepperIndex: checkListStepperIndex,
              });
            }
          );
        }
      }
    );
  };
  moveToSignatureStep = (responseJson, packageList, selectedPackage) => {
    this.setState(
      {
        currentStep: 1,
        packageList: packageList,
        isLoadingPage: false,
        package_management_id: this.state.roadmap_id
          ? this.state.custom_package_id
          : responseJson.data.attributes.package?.data?.id,
        selectedPackage: selectedPackage,
        availableCheckList:
          responseJson?.data?.attributes?.checklists?.data || [],
      },
      () => {
        this.getSignatureRequest();
      }
    );
  };

  getAlReadySignedRequests = (responseJson) => {
    let allreadysigneddocs = [];
    if (responseJson.data.attributes.document_signed?.length > 0) {
      allreadysigneddocs = responseJson.data.attributes.document_signed
        .filter((sign) => {
          return sign.status;
        })
        .map((document) => {
          const temp = {};
          temp["template_id"] = document?.document_template_id;
          temp["fileName"] = null;
          temp["signature_id"] = document?.signature;
          temp["signature_url"] = null;
          return temp;
        });
    }
    return allreadysigneddocs;
  };
  getAvbleChecklist = (checkListDetail) => {
    const availableCheckList = [];
    const checkListStepperIndex = {};
    checkListDetail &&
      checkListDetail.length > 0 &&
      checkListDetail?.forEach((checklist) => {
        availableCheckList.push({ ...checklist });
        checkListStepperIndex[checklist?.id] = checkListStepperIndex[
          checklist?.id
        ]
          ? checkListStepperIndex[checklist?.id]
          : 0;
      });
    availableCheckList?.forEach((checklist) => {
      const sections = checklist?.attributes?.sections?.data;
      sections?.forEach((section) => {
        const questions = section?.attributes?.questions?.data;
        section.attributes.questions.data = questions;
      });
    });
    return { availableCheckList, checkListStepperIndex };
  };
  getCheckListDetail = (responseJson) => {
    const checkListDetail =
      responseJson?.data?.attributes?.checklists?.data || [];
    if (checkListDetail?.length > 0) {
      return this.getAvbleChecklist(checkListDetail);
    } else {
      if (responseJson?.data?.attributes?.package?.data?.id) {
        this.createChecklistforSelectedPackage(
          responseJson?.data?.attributes?.package?.data?.id
        );
      }
    }
  };
  handleCheckSurveyPaymentStatusResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.attributes) {
      this.setState(
        {
          allReadySignedRequests: this.getAlReadySignedRequests(responseJson),
        },
        () => {
          this.handdleNextResponse(responseJson);
        }
      );
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };
  handleNextRoadmapResponse = (responseJson, packageList) => {
    let package_management_id = null;
    let selectedPackage = null;
    let surveyPaymentStatus = null;
    if (responseJson.data.attributes.roadmap) {
      if (responseJson.data.attributes.roadmap.data) {
        if (responseJson.data.attributes.roadmap.data.attributes) {
          if (responseJson.data.attributes.roadmap.data.attributes.obs) {
            surveyPaymentStatus =
              responseJson.data.attributes?.roadmap?.data?.attributes?.obs
                ?.schedule?.id;
            const custom_packages =
              responseJson.data.attributes.roadmap?.data?.attributes
                ?.custom_packages.data;
            custom_packages.forEach((pkg) => {
              calculateMonthlyPaymentTerm(
                pkg?.attributes?.payment_term,
                packageList,
                pkg
              );
              calculateQuaturlyPaymentTerm(
                pkg?.attributes?.payment_term,
                packageList,
                pkg
              );
              calculateYearlyPaymentTerm(
                pkg?.attributes?.payment_term,
                packageList,
                pkg
              );
            });
            selectedPackage = packageList.find((pkg) => {
              return (
                pkg.paymentTermKey + "_" + pkg?.pkg?.id ==
                this.state.payment_term + "_" + this.state.custom_package_id
              );
            });
            package_management_id = this.state.custom_package_id;
          }
        }
      }
    }
    return {
      selectedPackage,
      package_management_id,
      packageList,
      surveyPaymentStatus,
    };
  };
  handdleNextResponse = (responseJson) => {
    let surveySignStatus = this.getSignStatus(responseJson);
    let surveyPaymentStatus = responseJson.data.attributes.payment_status;
    let packageList = [];
    let package_management_id = null;
    let selectedPackage = null;
    if (this.state.roadmap_id) {
      const a = this.handleNextRoadmapResponse(responseJson, packageList);
      packageList = a.packageList;
      selectedPackage = a.selectedPackage;
      package_management_id = a.package_management_id;
      surveyPaymentStatus = a.surveyPaymentStatus;
    } else {
      packageList =
        responseJson.data.attributes.survey_packages?.data?.attributes
          ?.packages || [];
      package_management_id =
        responseJson.data.attributes.package?.data?.id || null;
      selectedPackage = responseJson.data.attributes.package?.data || null;
      if (!surveyPaymentStatus) {
        surveyPaymentStatus = responseJson.data.attributes.survey_packages?.data?.attributes?.recurring_charge?.id
      }
    }

    const chklist = this.getCheckListDetail(responseJson);
    this.setState(
      {
        surveyPaymentStatus: surveyPaymentStatus,
        surveySignatureStatus: surveySignStatus,
        packageList: packageList,
        isLoadingPage: false,
        package_management_id: package_management_id,
        selectedPackage: selectedPackage,
        checkListDetail: responseJson.data.attributes.checklists?.data || [],
        availableCheckList: chklist?.availableCheckList || [],
        checkListStepperIndex: chklist?.checkListStepperIndex || {},
      },
      () => {
        this.movetoNextStep(responseJson, packageList, selectedPackage);
      }
    );
  };
  movetoNextStep = (responseJson, packageList, selectedPackage) => {
    if (this.state?.surveyPaymentStatus && this.state.surveySignatureStatus) {
      this.updateClientStatus("Enrolled");
      this.updateOnboardingSurveyStatus("Enrolled");
      this.moveToChecklistPage(responseJson, packageList, selectedPackage);
    } else if (
      !this.state?.surveyPaymentStatus &&
      this.state.surveySignatureStatus
    ) {
      this.moveToPaymentStep(responseJson, packageList, selectedPackage);
    } else if (
      this.state?.surveyPaymentStatus &&
      !this.state.surveySignatureStatus
    ) {
      this.moveToSignatureStep(responseJson, packageList, selectedPackage);
    } else if (
      !this.state?.surveyPaymentStatus &&
      !this.state.surveySignatureStatus
    ) {
      if (this.state.roadmap_id) {
        this.moveToPackageListForRoadmap(
          responseJson,
          packageList,
          selectedPackage
        );
      } else {
        this.moveToPackageList(responseJson, packageList, selectedPackage);
      }
    }
  };
  getSignStatus = (responseJson) => {
    const allDocstobeSigned =
      responseJson.data.attributes?.document_signed || [];
    let surveySignStatus = false;
    if (allDocstobeSigned.length > 0) {
      if (
        this.state.allReadySignedRequests.length == allDocstobeSigned.length
      ) {
        surveySignStatus = true;
      }
    }
    return surveySignStatus;
  };
  moveToChecklistPage = (responseJson, packageList, selectedPackage) => {
    const answer = this.getAnswerOfEachQuestion(
      responseJson.data.attributes.checklists?.data
    );
    this.setState(
      {
        currentStep: 3,
        packageList: packageList,
        isLoadingPage: false,
        package_management_id: this.state.roadmap_id
          ? this.state.custom_package_id
          : responseJson.data.attributes.package?.data?.id,
        selectedPackage: selectedPackage,
        checkListDetail: responseJson.data.attributes.checklists?.data || [],
        onBoadringSurveyChecklistAnswer:
          answer?.onBoadringSurveyChecklistAnswer,
        completeStatus: Math.round(
          (answer?.totalAnswerCount / answer?.totalQuestionCount) * 100
        ),
        totalQuestions: answer?.totalQuestionCount,
      },
      () => {
        const availableCheckList = [];
        const checkListStepperIndex = {};
        this.state?.checkListDetail &&
          this.state?.checkListDetail.length > 0 &&
          this.state?.checkListDetail?.forEach((checklist) => {
            availableCheckList.push({ ...checklist });
            checkListStepperIndex[checklist?.id] = checkListStepperIndex[
              checklist?.id
            ]
              ? checkListStepperIndex[checklist?.id]
              : 0;
          });
        this.setState({
          availableCheckList: availableCheckList,
          checkListStepperIndex: checkListStepperIndex,
        });
      }
    );
  };
  moveToPackageListForRoadmap = (responseJson, packageList, selectedPackge) => {
    let current_step = this.state.allow_to_change_package_id ? 0 : 1;
    if (this.state.client_choice_package_management_id) {
      current_step = 1;
    }
    this.setState(
      {
        currentStep: selectedPackge ? current_step : 0,
        packageList: packageList,
        isLoadingPage: false,
        package_management_id: selectedPackge?.pkg?.id,
        selectedPackage: selectedPackge || null,
        availableCheckList: [],
      },
      () => {
        if (this.state.currentStep === 1) this.getSignatureRequest();
      }
    );
  };
  moveToPackageList = (responseJson, packageList, selectedPackage) => {
    let current_step = this.state.allow_to_change_package_id ? 0 : 1;
    if (this.state.client_choice_package_management_id) {
      current_step = 1;
    }
    this.setState(
      {
        currentStep: responseJson.data.attributes.package?.data?.id
          ? current_step
          : 0,
        packageList: packageList,
        isLoadingPage: false,
        package_management_id: this.state.roadmap_id
          ? this.state.custom_package_id
          : responseJson.data.attributes.package?.data?.id,
        selectedPackage: selectedPackage,
        availableCheckList: [],
      },
      () => {
        if (this.state.currentStep === 1) this.getSignatureRequest();
      }
    );
  };
  updateOnboardingSurveyStatusResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.message) {
      this.props.showToast({
        type: "success",
        message: responseJson?.message,
      });
    } else {
      this.handleApiError(responseJson);
    }
  };
  handleEmbeedurlResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      client.open(responseJson?.data);
    } else {
      this.handleApiError(responseJson);
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.saveForLaterApiCallId == apiRequestCallId) {
        this.handleSaveForlaterResponse(responseJson);
      } else if (apiRequestCallId === this.getEmbedUrlCallId) {
        this.handleEmbeedurlResponse(responseJson);
      } else if (apiRequestCallId === this.getSignatureRequestCallId) {
        this.handleSignatureRequestResponse(responseJson);
      } else if (this.updateCheckListApiCallId === apiRequestCallId) {
        this.handleUpdateCheckListResponse(responseJson);
      } else if (this.getCPADetialbyAccessTokenCallId === apiRequestCallId) {
        this.handleCPADetialbyAccessTokenResponse(responseJson);
      } else if (this.checkSurveyPaymentStatusApiCallId === apiRequestCallId) {
        this.handleCheckSurveyPaymentStatusResponse(responseJson);
      } else if (this.collectPaymentApiCallId === apiRequestCallId) {
        this.handlePaymentAPiResponse(responseJson);
      } else if (this.saveOnBoardingSurveyApiCallId === apiRequestCallId) {
        this.saveOnBoardingSurveyResponse(responseJson);
      } else if (this.saveDocumentSignntureApiCallId === apiRequestCallId) {
        this.saveDocumentSignntureResponse(responseJson);
      } else if (this.updateOnboardingSurveyStatusCallId == apiRequestCallId) {
        this.updateOnboardingSurveyStatusResponse(responseJson);
      }
      else if (this.getsignDocsCallId == apiRequestCallId) {
        this.getSignatureRequest()
      } else if (this.getSingleClientApiCallId == apiRequestCallId) {
        this.handleMail(responseJson)
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showToast({
        type: "error",
        message: AlertBodyMessage,
      });
    }
  }
  handleSaveForlaterResponse = (responseJson) => {
    this.props.hideLoader();
    this.props.showToast({
      message: "Your response save successfully",
      type: "message",
    });
    setTimeout(() => {
      window.close();
    }, 1000);
  };

  handleMail = (response) => {
    this.setState({
      clientmail: response.email
    })
  }
  saveOnBoardingSurveyResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.id) {
      this.setState(
        {
          onboarding_survey_id: responseJson?.data?.id,
          package_management_id:
            responseJson?.data?.attributes.package_management_id,
          client_choice_package_management_id:
            responseJson?.data?.attributes.package_management_id,
          saved_for_later: responseJson?.data?.attributes?.saved_for_later,
        },
        () => {
          if (this.state.saved_for_later) {
            this.props.showToast({
              type: "error",
              message:
                "you can access this survey using our clinet portal.we have sent a mail to registered email id",
            });
            if (window.location.href.indexOf("dev") > -1) {
              window.location.href =
                "https://smartpathclientportal-271175-react.b271175.dev.eastus.az.svc.builder.cafe";
            } else if (window.location.href.indexOf("stage") > -1) {
              window.location.href =
                "https://smartpathclientportal-271175-react.b271175.stage.eastus.az.svc.builder.ai";
            }
          } else {
            this.checkSurveyPaymentStatus(this.state.cfsSurveyId);
          }
        }
      );
    }
  };
  saveDocumentSignntureResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.id) {
      const signatureRequests = [...this.state.signatureRequests];
      const index = signatureRequests.findIndex((signatureRequest) => {
        return (
          signatureRequest?.template_id ===
          responseJson?.data?.document_template_id
        );
      });
      signatureRequests[index] = {
        ...signatureRequests[index],
        isSigned: true,
      };
      this.setState({
        signatureRequests: signatureRequests,
      });
    }
  };

  
  handleRecurringPayment = (responseJson) => {
    if (responseJson?.data?.attributes?.schedule?.id) {
      this.checkSurveyPaymentStatus();
      this.props.showToast({
        type: "success",
        message: "payment scheduled successfully",
      });
      return true;
    } else {
      this.handleApiError(responseJson);
    }
    return false;
  };
  handlePaymentAPiResponse = (responseJson) => {
    this.props.hideLoader();
    if (this.state.roadmap_id) {
      this.handleRecurringPayment(responseJson);
    } else {
      if (!this.handleRecurringPayment(responseJson)) {
        if (responseJson?.data?.attributes?.transaction_id) {
          this.checkSurveyPaymentStatus();
          this.props.showToast({
            type: "success",
            message: "payment completed successfully",
          });
        } else {
          this.handleApiError(responseJson);
        }
      }
    }
  };
  getSignatureRequest = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSignatureRequestCallId = requestMessage.messageId;
    let url;
    if (this.state.cfsSurveyId) {
      url = `bx_block_pricingcalculator/client_information_onboardings/document_signatures?survey_id=${this.state.cfsSurveyId}&client_id=${this.state.clientId}&account_id=${this.state.accountId}`;
    } else {
      url = `bx_block_pricingcalculator/client_information_onboardings/document_signatures_for_roadmap?road_customization_id=${this.state.roadmap_id}&client_id=${this.state.clientId}&account_id=${this.state.accountId}`;
    }
    if (this.state.package_management_id && this.state.cfsSurveyId) {
      url += `&package_management_id=${this.state.package_management_id}`;
    }
    if (this.state.package_management_id && this.state.roadmap_id) {
      url += `&custom_package_id=${this.state.package_management_id}&parent_package_type=${this.state.parent_package_type}`;
    }

    if (!this.state?.cpaDetail?.cpa_charge_info) {
      url += `&cpa_not_enabled=true`;
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  getsignDocs = (sign,survey_id, document_template_id) => {

    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
    };
    
    const data = {
      "signature" : sign
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getsignDocsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_client_dashboard/sign_document?survey_id=${survey_id}&document_template_id=${document_template_id}&client_id=${this.state.clientId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getEmbedUrl = (signatureRequest_id, document_template_id) => {
    this.setState(
      {
        document_template_id: document_template_id,
      },
      () => {
        this.props.showLoader();

        const headers = {
          "content-type": "application/json",
        };

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getEmbedUrlCallId = requestMessage.messageId;

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_pricingcalculator/client_information_onboardings/sign_url?signature=${signatureRequest_id}`
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    );
  };
  setTheme = (theme1) => {
    localStorage.setItem(
      "themePrimaryColor",
      theme1.primary_color || themeProps?.palette?.primary?.main
    );
    localStorage.setItem(
      "themeSecondaryColor",
      theme1.secondary_color || themeProps?.palette?.primary?.main
    );
    this.setState({
      theme: {
        ...themeProps,
        palette: {
          ...themeProps?.palette,
          primary: {
            main: theme1.primary_color || themeProps?.palette?.primary?.main,
          },
          secondary: {
            main: "#444040",
          },
        },
        overrides: {
          ...themeProps.overrides,
          MuiButton: {
            ...themeProps.overrides.MuiButton,
            containedPrimary: {
              ...themeProps.overrides.MuiButton.containedPrimary,
              fontSize: "0.8rem",
            },
            textPrimary: {
              ...themeProps.overrides.MuiButton.textPrimary,
              color: theme1.primary_color,
            },
            outlinedPrimary: {
              ...themeProps.overrides.MuiButton.outlinedPrimary,
              color: theme1.primary_color,
            },
          },
          MuiOutlinedInput: {
            root: {
              ...themeProps.overrides.MuiOutlinedInput.root,
              "&.Mui-focused": {
                border: `2px solid ${theme1.primary_color ||
                  themeProps?.palette?.primary?.main}`,
                backgroundColor: "#fff",
              },
            },
          },
        },
      },
    });
  };

  getCPADetialbyAccessToken = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
    };
    const data = {
      data: {
        attributes: {
          access_token: "",
          account_id: this.state.accountId || "",
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCPADetialbyAccessTokenCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/access_token`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  checkSurveyPaymentStatus = (survey_id: number) => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.checkSurveyPaymentStatusApiCallId = requestMessage.messageId;
    let url = `bx_block_pricingcalculator/client_information_onboardings/onboarding_survey?client_id=${this.state.clientId}&account_id=${this.state.accountId}`;
    if (this.state.cfsSurveyId) {
      url += `&survey_id=${this.state.cfsSurveyId}`;
      if (this.state.package_management_id) {
        url += `&package_management_id=${this.state.package_management_id}`;
      }
    } else if (this.state.roadmap_id) {
      url += `&road_customization_id=${this.state.roadmap_id}`;
      if (this.state.custom_package_id) {
        url += `&custom_package_id=${this.state.custom_package_id}&parent_package_type=${this.state.parent_package_type}`;
      }
    }

    // &package_management_id=${}
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getPaymentTerm = (oldPaymentTerm) => {
    if (oldPaymentTerm == "is_monthly_selected") {
      return "MONTH";
    } else if (oldPaymentTerm == "is_quarterly_selected") {
      return "quarter";
    } else if (oldPaymentTerm == "is_yearly_selected") {
      return "year";
    }
  };
  getSelectPkg = () => {
    return this.state.roadmap_id
      ? this.state.packageList.find((pkg) => {
        return (
          pkg.paymentTermKey + "_" + pkg.pkg.id ==
          this.state.payment_term + "_" + this.state.custom_package_id
        );
      })
      : this.state.packageList.find((pkg) => {
        return pkg.id == this.state.package_management_id;
      });
  }

  collectPayment = (methodId) => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
      // token: lo,
    };
    const selectedPackage = this.getSelectPkg()
    if (selectedPackage) {
      const surveyData =
        monthly_packages.indexOf(selectedPackage.name) > -1
          ? {
            data: {
              attributes: {
                package_id: selectedPackage.id,
                amount: Math.round(selectedPackage.price / 12),
                survey_id: this.state.cfsSurveyId,
                method: methodId,
                account_id: this.state.accountId,
                payment_term: "MONTH",
              },
            },
          }
          : {
            data: {
              attributes: {
                package_id: selectedPackage.id,
                amount: Math.round(selectedPackage.price),
                survey_id: this.state.cfsSurveyId,
                access_token: this.state.paymentAccessToken,
                method: methodId,
                account_id: this.state.accountId,
              },
            },
          };
      if (!this.state.cfsSurveyId) {
        console.log(selectedPackage.value, "selectedPackage.value")
      }

      const data = this.state.cfsSurveyId
        ? surveyData
        : {
          data: {
            attributes: {
              custom_package_id: selectedPackage.pkg.id,
              amount:
                selectedPackage.value.indexOf("Upfront") > -1
                  ? Math.round(
                    parseFloat(
                      selectedPackage.value
                        .split("+")[1]
                        .split(" ")[2]
                        .replace(/,/g, "")
                    )
                  )
                  : Math.round(
                    parseFloat(
                      selectedPackage.value.split(" ")[1].replace(/,/g, "")
                    )
                  ),
              road_customization_id: this.state.roadmap_id,
              access_token: this.state.paymentaccesstoken,
              method: methodId,
              account_id: this.state.accountId,
              payment_term: this.getPaymentTerm(this.state.payment_term),
              upfront_amount:
                selectedPackage.value.indexOf("Upfront") > -1
                  ? Math.round(
                    parseFloat(
                      selectedPackage.value
                        ?.split("+")[0]
                        .split(" ")[1]
                        .replace(/,/g, "")
                    )
                  )
                  : 0,
            },
          },
        };
      // this.state.payment_term
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.collectPaymentApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        !this.state.roadmap_id
          ? `bx_block_survey/payments`
          : "bx_block_survey/create_payment_for_roadmap"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  saveDocumentSignnture = (signature) => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
      // token: lo,
    };
    const data = this.state.roadmap_id
      ? {
        client_id: this.state.clientId,
        road_customization_id: this.state.roadmap_id,
        signature: signature,
        document_template_id: this.state.document_template_id,
        custom_package_id: this.state.package_management_id,
      }
      : {
        client_id: this.state.clientId,
        survey_id: this.state.cfsSurveyId,
        signature: signature,
        document_template_id: this.state.document_template_id,
        package_management_id: this.state.package_management_id,
      };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveDocumentSignntureApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_information_onboardings/sign_document`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  saveOnBoardingSurvey = (saved_for_later?= false) => {
    //
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
      // token: lo,
    };
    let package_management_id = null;
    if (this.state.cfsSurveyId) {
      package_management_id = this.state.allow_to_change_package_id
        ? this.state.client_choice_package_management_id
        : this.state.package_management_id || undefined;
    } else {
      package_management_id = this.state.custom_package_id;
    }
    const data = {
      client_onboarding_survey: {
        package_management_id: this.state.roadmap_id
          ? undefined
          : package_management_id,
        custom_package_management_id: this.state.cfsSurveyId
          ? undefined
          : package_management_id,
        payment_term: this.state.cfsSurveyId
          ? undefined
          : this.state.payment_term,
        survey_id: this.state.cfsSurveyId || undefined,
        saved_for_later: !!saved_for_later,
        road_customization_id: this.state.roadmap_id || undefined,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveOnBoardingSurveyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_information_onboardings/${this.state.clientId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  isCheckListComplete = () => {
    let totalQuestionCount = 0;
    let totalAnswerCount = 0;
    this.state.availableCheckList
      .filter((chklist) => {
        return chklist?.id == this.state.selectedCheckListId;
      })
      .forEach((checklist) => {
        checklist?.attributes?.sections?.data?.forEach((section) => {
          section?.attributes?.questions?.data?.forEach((question) => {
            totalQuestionCount++;
            if (
              this.state.onBoadringSurveyChecklistAnswer[
                `checkListId:${this.state.selectedCheckListId}_section:${section?.id}_question:${question?.id}`
              ]?.answer
            ) {
              totalAnswerCount++;
            }
          });
        });
      });
    return totalQuestionCount == totalAnswerCount;
  };

  updateCheckList = () => {
    //
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
      // token: lo,
    };
    const data = {
      checklists: {
        id: +this.state.selectedCheckListId,
        is_completed: this.isCheckListComplete(),
        sections_attributes: [
          {
            id: +this.state.currentSection?.id,
            questions_attributes: [
              {
                id: +this.state.currentQuestion?.id,
                document: {
                  data: this.state.onBoadringSurveyChecklistAnswer[
                    `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
                  ]?.file_base64,
                },
                current_gathered_details: this.state.onBoadringSurveyChecklistAnswer[
                  `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
                ]?.answer?.toString(),
              },
            ],
          },
        ],
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCheckListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_information_onboardings/${this.state.clientId}/update_checklist`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // responseJson?.data?.attributes?.package?.data
  getCurrentSection = () => {
    const availableCheckList = this.state.availableCheckList;
    const index = availableCheckList?.findIndex((chkList) => {
      return chkList?.id === this.state.selectedCheckListId;
    });
    const checklist = availableCheckList[index];
    const section =
      checklist?.attributes?.sections?.data[
      this.state.checkListStepperIndex[this.state.selectedCheckListId]
      ];
    this.setState(
      {
        currentSection: section,
      },
      () => {
        this.setState(
          {
            section_question_index: 0,
          },
          () => {
            this.getNextQuestion(true);
          }
        );
      }
    );
  };
  getFirstQuestionOfSection = () => {
    this.setState(
      {
        section_question_index: 0,
      },
      () => {
        const currentSection = { ...this.state.currentSection };
        const questions = currentSection?.attributes?.questions?.data;
        this.setState({
          currentQuestion: questions[this.state.section_question_index],
        });
      }
    );
  };
  getCurrentUnansweredQues = (currentQuestions, temp) => {
    let currentQuestion = null;
    let currentQuestionIndex = 0;
    for (const question of currentQuestions) {
      if (question?.id == temp?.question) {
        currentQuestion = question;
        currentQuestionIndex = currentQuestions?.findIndex((qus) => {
          return qus.id == question.id;
        });
        break;
      }
    }
    return { currentQuestion, currentQuestionIndex };
  };
  getCurrentUnansweredSectionAndQuestion = (checklist, temp) => {
    let currentSection = null;
    let currentSectionIndex = 0;
    let currentQuestion = null;
    let currentQuestionIndex = 0;
    const sections = checklist?.attributes?.sections?.data;
    for (const section of sections) {
      if (section?.id == temp?.section) {
        currentSection = section;
        currentSectionIndex = sections?.findIndex((sec) => {
          return sec.id == section.id;
        });
        const currentQuestions = section?.attributes?.questions?.data;
        const ques = this.getCurrentUnansweredQues(currentQuestions, temp);
        currentQuestion = ques?.currentQuestion;
        currentQuestionIndex = ques?.currentQuestionIndex;
        break;
      }
    }
    return {
      currentSection,
      currentSectionIndex,
      currentQuestion,
      currentQuestionIndex,
    };
  };
  getCurrentUnansweredQuestion = (temp) => {
    const checklists = this.state.checkListDetail;
    let currentChecklist = null;
    let currentSection = null;
    let currentQuestion = null;
    let currentSectionIndex = 0;
    let currentQuestionIndex = 0;
    for (const checklist of checklists) {
      if (checklist?.id == temp?.checkListId) {
        currentChecklist = checklist;
        const sec = this.getCurrentUnansweredSectionAndQuestion(
          checklist,
          temp
        );
        currentSection = sec?.currentSection;
        currentSectionIndex = sec?.currentSectionIndex;
        currentQuestion = sec?.currentQuestion;
        currentQuestionIndex = sec?.currentQuestionIndex;
        break;
      }
    }
    return {
      currentChecklist,
      currentSection,
      currentQuestion,
      currentSectionIndex,
      currentQuestionIndex,
    };
  };
  getUnanswerQuestionifPresent = () => {
    for (const key of Object.keys(this.state.onBoadringSurveyChecklistAnswer)) {
      if (!this.state.onBoadringSurveyChecklistAnswer[key]?.answer) {
        const newchecklist = key.split("_");
        const temp = {};
        newchecklist.forEach((tt) => {
          const a = tt.split(":");
          temp[a[0]] = a[1];
        });
        const ques = this.getCurrentUnansweredQuestion(temp);
        this.setState((prevState) => {
          return {
            selectedCheckListId: ques?.currentChecklist?.id,
            checkListStepperIndex: {
              ...prevState.checkListStepperIndex,
              [+ques?.currentChecklist?.id]: ques?.currentSectionIndex,
            },
            section_question_index: ques?.currentQuestionIndex,
            currentQuestion: ques?.currentQuestion,
            currentSection: ques?.currentSection,
          };
        });
        break;
      }
    }
  };
  getNextQuestion = (isFirst) => {
    const ans = this.getAllAnsweredQuestionsCount();
    if (ans?.totalAnsweCount == ans?.totalQuestionCount) {
      this.setState(
        {
          currentStep: 5,
        },
        () => {
          this.updateClientStatus("Active");
          this.updateOnboardingSurveyStatus("completed");
        }
      );
    } else {
      if (isFirst) {
        this.getFirstQuestionOfSection();
        this.updateClientStatus("Enrolled");
        this.updateOnboardingSurveyStatus("Enrolled");
      } else {
        this.updateQuestionIndex();
        this.updateClientStatus("Onboarding");
        this.updateOnboardingSurveyStatus("Onboarding");
      }
    }
  };
  updateQuestionIndex = () => {
    this.setState(
      (prevState) => {
        return {
          section_question_index: prevState.section_question_index + 1,
        };
      },
      () => {
        const currentSection = { ...this.state.currentSection };
        if (
          currentSection?.attributes?.questions?.data?.length ===
          this.state.section_question_index
        ) {
          this.handleStepChange();
        } else if (
          this.state.section_question_index >
          currentSection?.attributes?.questions?.data?.length
        ) {
          const ans = this.getAllAnsweredQuestionsCount();
          if (ans?.totalAnsweCount === ans?.totalQuestionCount) {
            this.setState(
              {
                currentStep: 5,
              },
              () => {
                this.updateOnboardingSurveyStatus("completed");
              }
            );
          } else {
            this.getUnanswerQuestionifPresent();
          }
        } else {
          this.moveToNextQuestion();
        }
      }
    );
  };
  handleStepChange = () => {
    this.setState(
      (prevState) => {
        return {
          checkListStepperIndex: {
            ...prevState.checkListStepperIndex,
            [this.state.selectedCheckListId]:
              this.state.checkListStepperIndex[this.state.selectedCheckListId] +
              1,
          },
        };
      },
      () => {
        this.moveToNextCheckList();
      }
    );
  };
  moveToNextCheckList = () => {
    const availableCheckList = this.state.availableCheckList;
    const index = availableCheckList?.findIndex((chkList) => {
      return chkList?.id == this.state.selectedCheckListId;
    });
    const checklist = availableCheckList[index];
    if (
      this.state.checkListStepperIndex[this.state.selectedCheckListId] ===
      checklist?.attributes?.sections?.data?.length
    ) {
      if (index + 1 < availableCheckList.length) {
        const newSelectedChecklist = availableCheckList[index + 1];

        this.setState(
          {
            selectedCheckListId: newSelectedChecklist?.id,
          },
          () => {
            this.getCurrentSection();
          }
        );
      } else if (index + 1 === availableCheckList.length) {
        const ans = this.getAllAnsweredQuestionsCount();
        if (ans?.totalAnsweCount === ans?.totalQuestionCount) {
          this.setState(
            {
              currentStep: 5,
            },
            () => {
              this.updateOnboardingSurveyStatus("completed");
            }
          );
        } else {
          this.getUnanswerQuestionifPresent();
        }
      }
    } else {
      this.moveToNextSection(checklist);
    }
  };
  moveToNextQuestion = () => {
    const currentSection = { ...this.state.currentSection };
    const questions = currentSection?.attributes?.questions?.data;
    this.setState({
      currentQuestion: questions[this.state.section_question_index],
    });
  };
  moveToNextSection = (checklist) => {
    const section =
      checklist?.attributes?.sections?.data[
      this.state.checkListStepperIndex[this.state.selectedCheckListId]
      ];
    this.setState(
      {
        section_question_index: 0,
        currentSection: section,
      },
      () => {
        const currentSection = { ...this.state.currentSection };
        const questions = currentSection?.attributes?.questions?.data;
        this.setState({
          currentQuestion:
            questions?.length > 0
              ? questions[this.state.section_question_index]
              : null,
        });
      }
    );
  };
  getAllAnsweredQuestionsCount = () => {
    const totalQuestionCount = this.state.totalQuestions;
    let totalAnsweCount = 0;
    Object.keys(this.state.onBoadringSurveyChecklistAnswer).forEach(
      (questionKey) => {
        if (this.state.onBoadringSurveyChecklistAnswer[questionKey]?.answer) {
          totalAnsweCount++;
        }
      }
    );
    return { totalQuestionCount, totalAnsweCount };
  };
  goToPriviousSection = (sectionIndex: number) => {
    const availableCheckList = [...this.state.availableCheckList];
    const index = availableCheckList?.findIndex((chkList) => {
      return chkList?.id == this.state.selectedCheckListId;
    });
    const checklist = availableCheckList[index];
    const section = checklist?.attributes?.sections?.data[sectionIndex];
    this.setState(
      (prevState) => {
        return {
          checkListStepperIndex: {
            ...prevState.checkListStepperIndex,
            [this.state.selectedCheckListId]: sectionIndex,
          },
        };
      },
      () => {
        this.setState(
          {
            section_question_index: 0,
            currentSection: section,
          },
          () => {
            const currentSection = { ...this.state.currentSection };
            const questions = currentSection?.attributes?.questions?.data;
            this.setState({
              currentQuestion: questions[this.state.section_question_index],
            });
          }
        );
      }
    );
  };
  getAnswerOfEachQuestion = (checklists: any) => {
    const onBoadringSurveyChecklistAnswer = {};
    let totalQuestionCount = 0;
    let totalAnswerCount = 0;
    let count = 0;
    checklists.forEach((checklist) => {
      checklist?.attributes?.sections?.data?.forEach((section) => {
        section?.attributes?.questions?.data?.forEach((question) => {
          totalQuestionCount++;
          count++;
          if (question?.attributes?.current_gathered_details) {
            totalAnswerCount++;
          }
          onBoadringSurveyChecklistAnswer[
            `checkListId:${checklist?.id}_section:${section?.id}_question:${question?.id}`
          ] = {
            count,
            answer:
              (question?.attributes?.current_gathered_details?.indexOf(",") > -1
                ? question?.attributes?.current_gathered_details?.split(",")
                : question?.attributes?.current_gathered_details) || "",
            additional_notes: question?.attributes?.additional_notes || "",
            document: question?.attributes?.document,
          };
        });
      });
    });
    return {
      totalQuestionCount,
      totalAnswerCount,
      onBoadringSurveyChecklistAnswer,
    };
  };
  updateOnboardingSurveyStatus = (status: string) => {
    if (this.state.cfsSurveyId) {
      this.props.showLoader();

      const headers = {
        "content-type": "application/json",
      };
      const data = {
        survey_status: status,
        survey_id: this.state.cfsSurveyId || "",
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateOnboardingSurveyStatusCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_pricingcalculator/client_information_onboardings/update_suvey`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PATCH"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  getClientmail = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleClientApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_informations/get_client_email?client_id=${this.state.clientId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  updateClientStatus = (status: string) => {
    const headers = {
      "content-type": "application/json",
    };
    const data = {
      id: this.state.clientId,
      account_id: this.state.accountId,
      status,
      onboarding_date: moment().format("YYYY-MM-DD"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateClientStatusCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_clients/client_informations/update_client_status`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createChecklistforSelectedPackage = (package_management_id: number) => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createChecklistforSelectedPackageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_informations/${this.state.clientId}/create_checklists`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        package_management_id: package_management_id,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  saveForLater = () => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.validationApiContentType,
      // token: lo,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveForLaterApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_information_onboardings/save_for_later?id=${this.state.onboarding_survey_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
// Customizable Area End