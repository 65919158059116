//@ts-nocheck
import React from "react";
import SurveySteperController, { Props } from "./SurveySteperController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import { Prompt, withRouter } from "react-router-dom";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import { StepIconProps } from "@material-ui/core/StepIcon";
import clsx from "clsx";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import AboutUsSurveyStep from "./AboutUsSurveyStep.web";
import CashFlow from "./CashFlow.web";
import TaxSaving from "./TaxSaving.web";
import BusinessNeed from "./BusinessNeed.web";
import Saving from "./Saving.web";
import Reporting from "./Reporting.web";
import Summary from "./Summary.web";
import PaymentPage, { Thankyou } from "../CPAPayment.web.tsx";
import MeetingSchrdulePage from "./Schedule.web.tsx";
import PotentialSavings from "../PotentialSavings.web";
import { themeProps } from "../../../../web/src/theme2";
import { createTheme } from "@material-ui/core/styles";
import TernaryCheck from "../TernaryCheck.web";

const steps = [
  "About You",
  "Cash Flow",
  "Tax Savings",
  "Business Needs",
  "Savings",
  "Reporting",
  "Summary",
];
let checkSteps = 0;
const drawerWidth = 240;
const careerNeedRetirmentDescription =
  "I’m retired or I don’t need help with improving my future career earnings.";
const taxSavingMaximizingDescription =
  "Maximizing my tax savings isn’t important to me at this time.";
const cashflowDontNeedHelpDescription =
  "I’m okay with my current income & spending and don’t need any help with cash flow.";
const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 48,
    left: "calc(-50% + -40px)",
    right: "calc(50% + -40px)",
    display: "none",
  },
  active: {
    "& $line": {
      borderColor: theme.palette.primary.main,
    },
    left: "calc(-50% + -5px)",
    right: "calc(50% + -5px)",
  },
  completed: {
    "& $line": {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: "#EDF4F4",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);

const ColorStepLabel = withStyles((theme) => ({
  active: {
    color: `${theme.palette.primary.main}!important`,
    fontWeight: "bold!important",
    paddingBottom: "1rem",
    // borderBottom: "0.3125rem solid #54A4A6!important",
    textTransform: "capitalize",
    fontSize: "1.2rem",
  },
  completed: {
    color: `${theme.palette.primary.light}!important`,
    fontWeight: "bold!important",
    paddingBottom: "1rem",
    borderBottom: `0.3125rem solid ${theme.palette.primary.main}!important`,
    textTransform: "capitalize",

    fontSize: "1.2rem",
  },
  label: {
    cursor: "pointer",
    fontSize: "1.2rem",
    color: "#E0DFE2",
    paddingBottom: "1rem",
    borderBottom: "0.3125rem solid #FAF0FC",
    textTransform: "capitalize",
    width: "100%",
    // transition: "border 500ms ease-in"
    transition: "all 200ms ease",
  },
}))(StepLabel);

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    zIndex: 1,
    width: "1rem",
    height: "1rem",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FAF0FC",
    position: "absolute",
    bottom: "2px",
    right: "calc(50% - 0.5rem)",
    [theme.breakpoints.down("md")]: {
      bottom: "4px",
    },
  },
  active: {
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: "#FAF0FC",
    width: "1.25rem",
    height: "1.25rem",
    right: "calc(50% - 0.625rem)",
    bottom: 0,
    [theme.breakpoints.down("md")]: {
      bottom: "2px",
    },
  },
  completed: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    bottom: "2px",
    right: "calc(50% - 0.5rem)",
    [theme.breakpoints.down("md")]: {
      bottom: "4px",
    },
  },
}));

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    ></div>
  );
}

export class SurveySteper extends SurveySteperController {
  constructor(props: Props) {
    super(props);
  }
  rootRef = React.createRef(null);
  unloadCallback = (event) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
  };
  componentWillUnmount(): Promise<void> {
    window.removeEventListener("beforeunload", this.unloadCallback);
  }
  async componentDidMount() {
    window.addEventListener("beforeunload", this.unloadCallback);
    const data = this?.props?.history?.location?.state?.state;
    this.setState(
      {
        email: data?.clientDetail?.email,
        firstName: data?.clientDetail?.first_name,
        lastName: data?.clientDetail?.last_name,
        phoneNumber: data?.clientDetail?.phone_number,
        paymentAccessToken: data?.access_token,
        isPaymentEnable: data?.access_token ? true : false,
        cpaData: data?.cpaDetail,
        aboutUsEnable: data?.clientDetail?.email ? true : false,
        accountId: data?.accountId,
        cpaSurveySetting: data?.cpaSurveySetting || {},
        surveyLogId: data?.surveyLogId,
        type: data?.type,
        isEcheckPaymentAvailable: data?.isEcheckPaymentAvailable,
        theme: data?.cpaDetail?.theme,
      },
      () => {
        const cfs_survey_setting =
          this.state.cpaData?.survey_setting?.data?.attributes || {};
        const package_self_enrollment = this.state.package_self_enrollment?.map(
          (pkg) => {
            return {
              ...pkg,
              isAvailableForEnrollment: cfs_survey_setting[pkg.key],
            };
          }
        );
        this.setTheme(data?.cpaDetail?.theme);
        this.setState({
          package_self_enrollment: package_self_enrollment,
          self_enrollment: cfs_survey_setting?.self_enrollment,
        });
      }
    );
  }

  handleBack = () => {
    this.setState(
      {
        activeStep: this.state.activeStep - 1,
      },
      () => {
        this.rootRef.current?.scrollTo(0, 0);
      }
    );
  };

  handleReset = () => {
    this.setState(
      {
        activeStep: 0,
      },
      () => {
        this.rootRef.current?.scrollTo(0, 0);
      }
    );
  };
  updateStep = (step) => {
    this.setState(
      {
        activeStep: step,
      },
      () => {
        this.rootRef.current?.scrollTo(0, 0);
      }
    );
  };

  selectedCustomPackage = (pkg, index) => {
    this.setState({
      selectPackageIndex: index,
      selectPackage: pkg,
    });
  };
  setCurrentAboutUsStep = (currentAboutUsStep, callBackFunction) => {
    this.setState(
      {
        currentAboutUsStep: currentAboutUsStep,
      },
      () => {
        this.rootRef.current?.scrollTo(0, 0);
        if(callBackFunction){
          callBackFunction();
        }
        
      }
    );
  };
  setStep1Selection = (selection) => {
    this.setState({
      step1Selection: selection,
    });
  };
  setStep2Selection = (selection) => {
    this.setState({
      step2Selection: selection,
    });
  };
  setStep3Selection = (selection) => {
    const step3Selection = this.state.step3Selection.map((opt) => {
      return opt.id;
    });
    if (step3Selection.indexOf(selection.id) > -1) {
      this.setState({
        step3Selection: [...this.state.step3Selection].filter((opt) => {
          return opt.id != selection.id;
        }),
      });
    } else {
      this.setState({
        step3Selection: [...this.state.step3Selection, selection],
      });
    }
  };
  setSelectedPackage = (packageIndex) => {
    this.setState({
      selectedPackage: packageIndex,
    });
  };

  setAboutUsCashflowSelection = (selection) => {
    const aboutUsCashflowSelection = this.state.aboutUsCashflowSelection.map(
      (opt) => {
        return opt.id;
      }
    );
    if (aboutUsCashflowSelection.indexOf(selection.id) > -1) {
      this.setState({
        aboutUsCashflowSelection: [
          ...this.state.aboutUsCashflowSelection,
        ].filter((opt) => {
          return opt.id != selection.id;
        }),
      });
    } else {
      this.setState({
        aboutUsCashflowSelection: [
          ...this.state.aboutUsCashflowSelection,
          selection,
        ],
      });
    }
  };
  setCashflowSelection = (selection) => {
    const cashflowSelection = this.state.cashflowSelection.map((opt) => {
      return opt.id;
    });
    const cashflowDontNeedHelpId = this.state.cashflowSelection.find(
      (businessNeed) => {
        return (
          businessNeed.attributes.description ===
          cashflowDontNeedHelpDescription
        );
      }
    );

    if (selection.attributes.description === cashflowDontNeedHelpDescription) {
      if (cashflowSelection.indexOf(selection.id) > -1) {
        this.setState({
          cashflowSelection: [...this.state.cashflowSelection].filter((opt) => {
            return opt.id != selection.id;
          }),
        });
      } else {
        this.setState({
          cashflowSelection: [selection],
        });
      }
    } else {
      this.setCashflowSelectionNew(
        cashflowSelection,
        cashflowDontNeedHelpId,
        selection
      );
    }
  };
  setCashflowSelectionNew = (
    cashflowSelection,
    cashflowDontNeedHelpId,
    selection
  ) => {
    if (cashflowSelection.indexOf(cashflowDontNeedHelpId?.id) > -1) {
      this.setState(
        {
          cashflowSelection: [...this.state.cashflowSelection].filter((opt) => {
            return opt.id != cashflowDontNeedHelpId.id;
          }),
        },
        () => {
          if (cashflowSelection.indexOf(selection.id) > -1) {
            this.setState({
              cashflowSelection: [...this.state.cashflowSelection].filter(
                (opt) => {
                  return opt.id != selection.id;
                }
              ),
            });
          } else {
            this.setState({
              cashflowSelection: [...this.state.cashflowSelection, selection],
            });
          }
        }
      );
    } else {
      if (cashflowSelection.indexOf(selection.id) > -1) {
        this.setState({
          cashflowSelection: [...this.state.cashflowSelection].filter((opt) => {
            return opt.id != selection.id;
          }),
        });
      } else {
        this.setState({
          cashflowSelection: [...this.state.cashflowSelection, selection],
        });
      }
    }
  };

  setTaxSavingSelection = (selection) => {
    const taxSavingSelection = this.state.taxSavingSelection.map((opt) => {
      return opt.id;
    });
    const taxSavingMaximizingTaxId = this.state.taxSavingSelection.find(
      (businessNeed) => {
        return (
          businessNeed.attributes.description === taxSavingMaximizingDescription
        );
      }
    );

    if (selection.attributes.description === taxSavingMaximizingDescription) {
      if (taxSavingSelection.indexOf(selection.id) > -1) {
        this.setState({
          taxSavingSelection: [...this.state.taxSavingSelection].filter(
            (opt) => {
              return opt.id != selection.id;
            }
          ),
        });
      } else {
        this.setState({
          taxSavingSelection: [selection],
        });
      }
    } else {
      this.setTaxSavingSelectionNew(
        taxSavingSelection,
        taxSavingMaximizingTaxId,
        selection
      );
    }
  };
  setTaxSavingSelectionNew = (
    taxSavingSelection,
    taxSavingMaximizingTaxId,
    selection
  ) => {
    if (taxSavingSelection.indexOf(taxSavingMaximizingTaxId?.id) > -1) {
      this.setState(
        {
          taxSavingSelection: [...this.state.taxSavingSelection].filter(
            (opt) => {
              return opt.id != taxSavingMaximizingTaxId.id;
            }
          ),
        },
        () => {
          if (taxSavingSelection.indexOf(selection.id) > -1) {
            this.setState({
              taxSavingSelection: [...this.state.taxSavingSelection].filter(
                (opt) => {
                  return opt.id != selection.id;
                }
              ),
            });
          } else {
            this.setState({
              taxSavingSelection: [...this.state.taxSavingSelection, selection],
            });
          }
        }
      );
    } else if (taxSavingSelection.indexOf(selection.id) > -1) {
      this.setState({
        taxSavingSelection: [...this.state.taxSavingSelection].filter((opt) => {
          return opt.id != selection.id;
        }),
      });
    } else {
      this.setState({
        taxSavingSelection: [...this.state.taxSavingSelection, selection],
      });
    }
  };
  setBusinessNeedSelection = (selection) => {
    const businessNeedSelection = this.state.businessNeedSelection.map(
      (opt) => {
        return opt?.id;
      }
    );
    const businessNeedReireId = this.state.businessNeedSelection.find(
      (businessNeed) => {
        return (
          businessNeed?.attributes?.description ===
          careerNeedRetirmentDescription
        );
      }
    );

    if (selection.attributes.description === careerNeedRetirmentDescription) {
      if (businessNeedSelection.indexOf(selection.id) > -1) {
        this.setState({
          businessNeedSelection: [...this.state.businessNeedSelection].filter(
            (opt) => {
              return opt?.id != selection?.id;
            }
          ),
        });
      } else {
        this.setState({
          businessNeedSelection: [selection],
        });
      }
    } else {
      this.setBusinessNeedSelectionNew(
        businessNeedSelection,
        businessNeedReireId,
        selection
      );
    }
  };
  setBusinessNeedSelectionNew = (
    businessNeedSelection,
    businessNeedReireId,
    selection
  ) => {
    if (businessNeedSelection.indexOf(businessNeedReireId?.id) > -1) {
      this.setState(
        {
          businessNeedSelection: [...this.state.businessNeedSelection].filter(
            (opt) => {
              return opt?.id != businessNeedReireId?.id;
            }
          ),
        },
        () => {
          if (businessNeedSelection.indexOf(selection.id) > -1) {
            this.setState({
              businessNeedSelection: [
                ...this.state.businessNeedSelection,
              ].filter((opt) => {
                return opt?.id != selection.id;
              }),
            });
          } else {
            this.setState({
              businessNeedSelection: [
                ...this.state.businessNeedSelection,
                selection,
              ],
            });
          }
        }
      );
    } else if (businessNeedSelection.indexOf(selection.id) > -1) {
      this.setState({
        businessNeedSelection: [...this.state.businessNeedSelection].filter(
          (opt) => {
            return opt?.id != selection.id;
          }
        ),
      });
    } else {
      this.setState({
        businessNeedSelection: [...this.state.businessNeedSelection, selection],
      });
    }
  };
  setSavingSelection = (selection) => {
    const savingSelection = this.state.savingSelection.map((opt) => {
      return opt.id;
    });
    if (savingSelection.indexOf(selection.id) > -1) {
      this.setState({
        savingSelection: [...this.state.savingSelection].filter((opt) => {
          return opt.id != selection.id;
        }),
      });
    } else {
      this.setState({
        savingSelection: [...this.state.savingSelection, selection],
      });
    }
  };
  setReportingSelection = (selection) => {
    const reportSelection = this.state.reportSelection.map((opt) => {
      return opt?.id;
    });
    if (reportSelection.indexOf(selection.id) > -1) {
      this.setState({
        reportSelection: [...this.state.reportSelection].filter((opt) => {
          return opt?.id != selection.id;
        }),
      });
    } else {
      this.setState({
        reportSelection: [...this.state.reportSelection, selection],
      });
    }
  };
  setValueByDynamicName = (name, value) => {
    this.setState({
      [name]: value,
    });
  };
  handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      paymentType: (event.target as HTMLInputElement).value,
    });
  };
  goToSummaryPage = () => {
    if (this.state.taxPayerType == "business") {
      this.setState({
        activeStep: 6,
        openFaq: true,
      });
    } else {
      this.setState({
        activeStep: 5,
        openFaq: true,
      });
    }
  };
  childStepContent = (step) => {
    if (step === 0) {
      return (
        <AboutUsSurveyStep
          getHeadingOfStep3={this.getHeadingOfStep3.bind(this)}
          getStepno3byStep1AndStep2={this.getStepno3byStep1AndStep2.bind(this)}
          isAboutusEnable={this.state.aboutUsEnable}
          setManagePayroll={this.setManagePayroll.bind(this)}
          updateStep={this.updateStep}
          handleNext={this.handleNext}
          handleBack={this.handleBack}
          setTaxPerType={this.setTaxPerType}
          taxPayerType={this.state.taxPayerType}
          stepContent={this.state.stepContent}
          getQuestionAndOptionsByStepNo={this.getQuestionAndOptionsByStepNo.bind(
            this
          )}
          currentAboutUsStep={this.state.currentAboutUsStep}
          setCurrentAboutUsStep={this.setCurrentAboutUsStep.bind(this)}
          step1Selection={this.state.step1Selection}
          step2Selection={this.state.step2Selection}
          step3Selection={this.state.step3Selection}
          setStep1Selection={this.setStep1Selection.bind(this)}
          setStep2Selection={this.setStep2Selection.bind(this)}
          setStep3Selection={this.setStep3Selection.bind(this)}
          totalAnnualIncome={this.state.totalAnnualIncome}
          taxFillingStatus={this.state.taxFillingStatus}
          isRealStateBusinessOutSideResidense={
            this.state.isRealStateBusinessOutSideResidense
          }
          isTradeOnMonthlyBasis={this.state.isTradeOnMonthlyBasis}
          isTradeCrypto={this.state.isTradeCrypto}
          isOwnerShipInAnotherBusiness={this.state.isOwnerShipInAnotherBusiness}
          isIncomeInAnotherStateOrCountary={
            this.state.isIncomeInAnotherStateOrCountary
          }
          isActiveIssueWithIRS={this.state.isActiveIssueWithIRS}
          isAllIncomeinOneState={this.state.isAllIncomeinOneState}
          noOfStateIncomeEarnIn={this.state.noOfStateIncomeEarnIn}
          no_of_countries_earn={this.state.no_of_countries_earn}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          email={this.state.email}
          phoneNumber={this.state.phoneNumber}
          currentAccountingSystem={this.state.currentAccountingSystem}
          currentEntityStructure={this.state.currentEntityStructure}
          currentPayRollSystem={this.state.currentPayRollSystem}
          industryType={this.state.industryType}
          annualRevenue={this.state.annualRevenue}
          otherBusinessPartner={this.state.otherBusinessPartner}
          noOfEmployee={this.state.noOfEmployee}
          isManagePayroll={this.state.isManagePayroll}
          isHelpNeededinAccounting={this.state.isHelpNeededinAccounting}
          noOFBankAccount={this.state.noOFBankAccount}
          isManageInvoice={this.state.isManageInvoice}
          noOFInvoice={this.state.noOFInvoice}
          isBalanceSheetAccurate={this.state.isBalanceSheetAccurate}
          noOfmonthForBalanceSheet={this.state.noOfmonthForBalanceSheet}
          setValueByDynamicName={this.setValueByDynamicName.bind(this)}
          aboutUsCashflowSelection={this.state.aboutUsCashflowSelection}
          setAboutUsCashflowSelection={this.setAboutUsCashflowSelection.bind(
            this
          )}
          cpaDetail={this.state.cpaData}
          iconColor={this.state.cpaData?.theme?.primary_color}
        />
      );
    } else if (step === 1) {
      return (
        <CashFlow
          handleNext={this.handleNext}
          handleBack={this.handleBack}
          setTaxPerType={this.setTaxPerType}
          stepContent={this.state.stepContent}
          taxPayerType={this.state.taxPayerType}
          cashflowSelection={this.state.cashflowSelection}
          setCashflowSelection={this.setCashflowSelection.bind(this)}
          getQuestionAndOptionsByStepNo={this.getQuestionAndOptionsByStepNo.bind(
            this
          )}
          iconColor={this.state.cpaData?.theme?.primary_color}
        />
      );
    } else if (step === 2) {
      return (
        <TaxSaving
          handleNext={this.handleNext}
          handleBack={this.handleBack}
          setTaxPerType={this.setTaxPerType}
          taxPayerType={this.state.taxPayerType}
          stepContent={this.state.stepContent}
          taxSavingSelection={this.state.taxSavingSelection}
          setTaxSavingSelection={this.setTaxSavingSelection.bind(this)}
          getQuestionAndOptionsByStepNo={this.getQuestionAndOptionsByStepNo.bind(
            this
          )}
          iconColor={this.state.cpaData?.theme?.primary_color}
        />
      );
    } else if (step === 3) {
      return (
        <BusinessNeed
          handleNext={this.handleNext}
          handleBack={this.handleBack}
          setTaxPerType={this.setTaxPerType}
          stepContent={this.state.stepContent}
          taxPayerType={this.state.taxPayerType}
          businessNeedSelection={this.state.businessNeedSelection}
          setBusinessNeedSelection={this.setBusinessNeedSelection.bind(this)}
          getQuestionAndOptionsByStepNo={this.getQuestionAndOptionsByStepNo.bind(
            this
          )}
          iconColor={this.state.cpaData?.theme?.primary_color}
        />
      );
    }
  };
  getStepContent = (step: number, classes = {}) => {
    if (step < 4) {
      return this.childStepContent(step);
    } else if (step === 4) {
      return this.state.taxPayerType === "business" ? (
        <Saving
          handleNext={this.handleNext}
          handleBack={this.handleBack}
          setTaxPerType={this.setTaxPerType}
          stepContent={this.state.stepContent}
          taxPayerType={this.state.taxPayerType}
          setSavingSelection={this.setSavingSelection.bind(this)}
          savingSelection={this.state.savingSelection}
          getQuestionAndOptionsByStepNo={this.getQuestionAndOptionsByStepNo.bind(
            this
          )}
          saveSurvey={this.saveSurvey.bind(this)}
          iconColor={this.state.cpaData?.theme?.primary_color}
        />
      ) : (
        <Reporting
          handleNext={this.handleNext}
          handleBack={this.handleBack}
          setTaxPerType={this.setTaxPerType}
          stepContent={this.state.stepContent}
          taxPayerType={this.state.taxPayerType}
          setReportingSelection={this.setReportingSelection.bind(this)}
          reportSelection={this.state.reportSelection}
          getQuestionAndOptionsByStepNo={this.getQuestionAndOptionsByStepNo.bind(
            this
          )}
          isLoaderStep={this.state.isLoaderStep}
          saveSurvey={this.saveSurvey.bind(this)}
          iconColor={this.state.cpaData?.theme?.primary_color}
        />
      );
    } else if (step === 5) {
      return this.state.taxPayerType !== "business" ? (
        <Summary
          openFaq={this.state.openFaq}
          isPaymentEnable={this.state.isPaymentEnable}
          createChecklistforSelectedPackage={this.createChecklistforSelectedPackage.bind(
            this
          )}
          cpaDetail={this.state.cpaData}
          handleNext={this.handleNext}
          handleBack={this.handleBack}
          updateStep={this.updateStep.bind(this)}
          setTaxPerType={this.setTaxPerType}
          taxPayerType={this.state.taxPayerType}
          setCurrentSummaryStep={this.setCurrentSummaryStep.bind(this)}
          currentSummaryStep={this.state.currentSummaryStep}
          packageList={this.state.packageList}
          selectedPackage={this.state.selectedPackage}
          setSelectedPackage={this.setSelectedPackage.bind(this)}
          getQuestionAndOptionsByStepNo={this.getQuestionAndOptionsByStepNo.bind(
            this
          )}
          collectPayment={this.collectPayment}
          saveSurvey={this.saveSurvey.bind(this)}
          handleRadioChange={this.handleRadioChange}
          paymentType={this.state.paymentType}
          sendEmailTemplate={this.sendEmailTemplate.bind(this)}
          survey_id={this.state.survey_id}
          cpaSurveySetting={this.state.cpaSurveySetting}
          surveyDetail={this.state.surveyDetail}
          package_self_enrollment={this.state.package_self_enrollment}
          openPotentialSavings={this.openPotentialSavings}
        />
      ) : (
        <Reporting
          handleNext={this.handleNext}
          handleBack={this.handleBack}
          setTaxPerType={this.setTaxPerType}
          stepContent={this.state.stepContent}
          taxPayerType={this.state.taxPayerType}
          setReportingSelection={this.setReportingSelection.bind(this)}
          reportSelection={this.state.reportSelection}
          getQuestionAndOptionsByStepNo={this.getQuestionAndOptionsByStepNo.bind(
            this
          )}
          iconColor={this.state.cpaData?.theme?.primary_color}
          saveSurvey={this.saveSurvey.bind(this)}
          isLoaderStep={this.state.isLoaderStep}
        />
      );
    } else if (step === 6) {
      return this.state.taxPayerType !== "business" ? (
        this.getDynamicPaymentPage()
      ) : (
        <Summary
          openFaq={this.state.openFaq}
          createChecklistforSelectedPackage={this.createChecklistforSelectedPackage.bind(
            this
          )}
          surveyDetail={this.state.surveyDetail}
          survey_id={this.state.survey_id}
          isPaymentEnable={this.state.isPaymentEnable}
          cpaDetail={this.state.cpaData}
          handleNext={this.handleNext}
          handleBack={this.handleBack}
          updateStep={this.updateStep.bind(this)}
          currentSummaryStep={this.state.currentSummaryStep}
          setCurrentSummaryStep={this.setCurrentSummaryStep.bind(this)}
          selectedPackage={this.state.selectedPackage}
          setSelectedPackage={this.setSelectedPackage.bind(this)}
          setTaxPerType={this.setTaxPerType}
          taxPayerType={this.state.taxPayerType}
          packageList={this.state.packageList}
          getQuestionAndOptionsByStepNo={this.getQuestionAndOptionsByStepNo.bind(
            this
          )}
          collectPayment={this.collectPayment}
          handleRadioChange={this.handleRadioChange}
          paymentType={this.state.paymentType}
          sendEmailTemplate={this.sendEmailTemplate.bind(this)}
          cpaSurveySetting={this.state.cpaSurveySetting}
          package_self_enrollment={this.state.package_self_enrollment}
          openPotentialSavings={this.openPotentialSavings}
        />
      );
    } else if (step === 7) {
      return this.state.taxPayerType == "business" ? (
        this.getDynamicPaymentPage()
      ) : (
        <MeetingSchrdulePage
          cpaDetail={this.state.cpaData}
          goToSummaryPage={this.goToSummaryPage}
        />
      );
    } else if (step === 8) {
      return (
        <MeetingSchrdulePage
          cpaDetail={this.state.cpaData}
          goToSummaryPage={this.goToSummaryPage}
        />
      );
    }
  };
  getDynamicPaymentPage = () => {
    if (this.state.isPaymentEnable) {
      return (
        <PaymentPage
          cpaDetail={this.state.cpaData}
          isPaymentSuccess={this.state.isPaymentSuccess}
          selectedPackage={this.state.selectedPackage}
          collectPayment={this.collectPayment.bind(this)}
          handleNext={this.handleNext}
          handleBack={this.handleBack}
          isEcheckPaymentAvailable={this.state.isEcheckPaymentAvailable}
          openPotentialSavings={this.openPotentialSavings}
          package_management_id={this.state.selectedPackage?.id}
          paymentCategory="cfsSurvey"
          showToast={this.props.showToast}
        />
      );
    } else {
      return (
        <MeetingSchrdulePage
          cpaDetail={this.state.cpaData}
          goToSummaryPage={this.goToSummaryPage}
        />
      );
    }
  };
  setTheme = (theme) => {
    this.setState({
      theme: {
        ...themeProps,
        palette: {
          ...themeProps?.palette,
          primary: {
            main: theme?.primary_color || themeProps?.palette?.primary?.main,
          },
          secondary: {
            main:
              theme?.secondary_color || themeProps?.palette?.secondary?.main,
          },
        },
        overrides: {
          ...themeProps.overrides,
          MuiButton: {
            textPrimary: {
              ...themeProps.overrides.MuiButton.textPrimary,
              color: theme?.primary_color || themeProps?.palette?.primary?.main,
              height: "3.8rem",
            },
          },
          MuiOutlinedInput: {
            ...themeProps.overrides.MuiOutlinedInput,
            root: {
              ...themeProps.overrides.MuiOutlinedInput.root,
              "&.Mui-focused": {
                border: `2px solid ${theme?.primary_color ||
                  themeProps?.palette?.primary?.main}`,
                backgroundColor: "#fff",
              },
            },
          },
        },
      },
    });
  };
  handleDrawerToggle = () => {
    this.setState({
      mobileOpen: !this.state.mobileOpen,
    });
  };

  formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };
  openPotentialSavings = () => {
    this.props.openDialogBox({
      title: "",
      width: "60%",
      renderedComponent: PotentialSavings,
      withCustomDialog: false,
    });
  };
  renderLogo = (classes) => {
    return this.state.cpaData?.theme?.logo ? (
      <img
        src={this.state.cpaData?.theme?.logo}
        alt="logo"
        style={{
          height: "90%",
          borderRadius: "0.25rem",
          marginRight: "auto",
          position: "absolute",
          left: "1%",
          top: "4px",
        }}
      />
    ) : (
      <Typography color="primary" className={classes.firmNameInHeader}>{this.state.cpaData?.theme?.firm_name}</Typography>
    );
  };
  getHeadingOfStep3 = (step) => {
    let content = "";
    switch (step) {
      case 1.1:
      case 1.4:
        content =
          "Is there anything missing in your current relationship wish your tax pro?";
        break;
      case 1.2:
      case 1.5:
        content =
          "What results would be valuable enough for you to start using a tax pro?";
        break;
      case 1.3:
      case 1.6:
        content =
          "Is there any specific direction you'd like us to be focusing our time more on?";
        break;
    }
    return content;
  };

  render() {
    const { classes } = this.props;
    let steps = [];
    if (this.state.taxPayerType === "business") {
      steps = [
        "About You",
        "Cash Flow",
        "Tax Savings",
        "Business Needs",
        "Savings",
        "Reporting",
        "Summary",
      ];
    } else {
      steps = [
        "About You",
        "Cash Flow",
        "Tax Savings",
        "Career Needs",
        "Reporting",
        "Summary",
      ];
    }

    if (this.state.isPaymentEnable) {
      steps.push("Payment");
    }
    if (this.state?.cpaData?.survey_setting?.data?.attributes?.meeting_link) {
      steps.push("Schedule");
    }
    return (
      <ThemeProvider theme={createTheme(this.state.theme)}>
        <div style={{ position: "relative" }}>
          <Prompt
            when={
              !!this.state.step1Selection &&
              !!this.state.survey_id &&
              !this.state.selectedPackage
            }
            message="Changes that you made may not be saved"
          />
          <AppBar
            position="sticky"
            color="default"
            elevation={1}
            className={classes.appBar}
          >
            <Toolbar className={classes.toolbar}>
              {this.renderLogo(classes)}
              <TernaryCheck
                condition={!this.state.isPaymentSuccess}
                trueComponent={
                  <Stepper
                    alternativeLabel
                    connector={<ColorlibConnector />}
                    activeStep={this.state.activeStep}
                    className={classes.stepperBox}
                    style={{
                      padding: "0px",
                    }}
                  >
                    {steps.map((label, index) => {
                      return (
                        <Step
                          key={label}
                          active={this.state.activeStep === index}
                          style={{
                            padding: 0,
                          }}
                        >
                          <ColorStepLabel
                            StepIconComponent={ColorlibStepIcon}
                            classes={{
                              label:
                                this.state.activeStep === index
                                  ? classes.stepLabelContainerActive
                                  : classes.stepLabelContainer,
                            }}
                            active={this.state.activeStep === index}
                            onClick={() =>
                              index <= this.state.activeStep &&
                              this.updateStep(index)
                            }
                          >
                            {label}
                          </ColorStepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                }
                falseComponent={null}
              />
            </Toolbar>
          </AppBar>
          <Grid
            container
            ref={this.rootRef}
            alignItems="center"
            className={classes.root}
            justifyContent="center"
          >
            <TernaryCheck
              condition={!this.state.isPaymentSuccess}
              trueComponent={
                <Grid item xs={12}>
                  {this.state.cpaData &&
                    this.getStepContent(this.state.activeStep)}
                </Grid>
              }
              falseComponent={
                <Thankyou selectedPackage={this.state.selectedPackage} />
              }
            />
          </Grid>
          <AppBar
            position="fixed"
            color="default"
            elevation={1}
            className={classes.bottomAppBar}
          >
            <Toolbar className={classes.bottomToolbar}>
              <Grid
                container
                alignItems="center"
                style={{ padding: "16px", borderTop: "1px solid #dfdfdf" }}
              >
                <Grid item xs={11} sm={8}>
                  <Typography
                    color="textSecondary"
                    className={classes.contactDetailsFooter}
                  >
                    {this.state.cpaData.theme?.firm_name ? this.state.cpaData.theme.firm_name : this.state.cpaData.firm_name}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    className={classes.contactDetailsFooter}
                  >
                    {this.state.cpaData?.theme?.web_url}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    className={classes.contactDetailsFooter}
                  >
                    {this.formatPhoneNumber(
                      this.state.cpaData?.theme?.phone_number
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={11} sm={4} style={{ flex: 1, textAlign: "end" }}>
                  <Typography
                    color="textSecondary"
                    display="inline"
                    className={classes.poweredByText}
                  >
                    Powered by
                    <Typography
                      color="textSecondary"
                      display="inline"
                      className={classes.poweredBySmartPath}
                      onClick={() => {
                        window.open("https://smartpath.co/", "_blank","noopener");
                      }}
                    >
                      Smartpath.co
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </div>
      </ThemeProvider>
    );
  }
}
const SurveySteperWithRouter = withRouter(SurveySteper);
const SurveySteperWithToast = withToast(SurveySteperWithRouter);
const SurveySteperWithLoader = withLoader(SurveySteperWithToast);
const SurveySteperWithAlertBox = withAlertBox(SurveySteperWithLoader);
const SurveySteperWithDialogBox = withDialogBox(SurveySteperWithAlertBox);

export default withStyles((theme) =>
  createStyles(
    {
      root: {
        minHeight: "calc(100vh - 140px)",
        maxHeight: "calc(100vh - 140px)",
        paddingTop: "1rem",
        overflow: "auto",
        [theme.breakpoints.down("md")]: {
          minHeight: "calc(100vh - 190px)",
          maxHeight: "calc(100vh - 190px)",
        },
        "&::-webkit-scrollbar": {
          width: "8px",
          backgroundColor: "#F5F5F5",
        },
        "&::-webkit-scrollbar-track": {
          // boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
          backgroundColor: "#fff",
          borderRadius: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#C7C7C8",
          borderRadius: "8px",
        },
      },
      appBar: {
        width: "100%",
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: "none",
        backgroundColor: " #ffffff",
      },
      bottomAppBar: {
        top: "auto",
        bottom: 0,
        width: "100%",
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: " #ffffff",
        "@media (max-width:500px)": {
          bottom: "unset",
        },
      },
      toolbar: {
        flexWrap: "nowrap",
        justifyContent: "center",
        position: "relative",
      },
      bottomToolbar: {
        flexWrap: "nowrap",
        minHeight: "50px",
      },
      mainHeading2: {
        maxWidth: "399px",
        textAlign: "right",
      },
      stepperBox: {
        transform: "scale(1.1)",
        "@media(min-width: 510px) and (max-width: 960px)": {
          transform: "scale(0.8)",
        },
        "@media (min-width: 600px) and (max-width: 1100px)": {
          marginTop: "60px",
        },
        "@media(max-width: 600px)": {
          marginTop: "40px",
        },
        "@media(max-width: 510px)": {
          transform: "scale(0.5)",
        },
        "@media(max-width: 315px)": {
          transform: "scale(0.4)",
        },
      },
      toolbarLogo: {
        flex: "0 0 12%",
        position: "absolute",
        left: 10,
        top: "10px",
        width: "100px",
        "@media(max-width: 1100px)": {
          width: "70px",
        },
        "@media(max-width: 600px)": {
          width: "45px",
        },
      },
      stepLabelContainer: {
        width: "9rem",
        order: -1,
        marginBottom: "8px",
      },
      stepLabelContainerActive: {
        width: "9rem",
        order: -1,
        marginBottom: "8px",
        position: "relative",
        "&::after": {
          content: "''",
          display: "block",
          position: "absolute",
          width: "50%",
          borderBottom: `5px solid ${localStorage.getItem(
            "themePrimaryColor"
          ) || theme.palette.primary.main}`,
          bottom: -5,
          [theme.breakpoints.down("lg")]: {
            borderBottom: `4px solid ${localStorage.getItem(
              "themePrimaryColor"
            ) || theme.palette.primary.main}`,
            bottom: -4,
          },
          [theme.breakpoints.down("md")]: {
            borderBottom: `3px solid ${localStorage.getItem(
              "themePrimaryColor"
            ) || theme.palette.primary.main}`,
            bottom: -3,
          },
        },
      },
      contactDetailsFooter: {
        display: "inline-block",
        fontFamily: "Nunito Sans",
        fontSize: "1.2rem",
        marginRight: "3rem",
        fontWeight: 600,
      },
      poweredByText: {
        display: "inline-block",
        fontFamily: "Nunito Sans",
        fontSize: "0.8rem",
        marginRight: "3rem",
        fontWeight: 600,
        opacity: 0.6,
      },
      poweredBySmartPath: {
        display: "inline-block",
        fontFamily: "Nunito Sans",
        fontSize: "0.8rem",
        marginRight: "3rem",
        fontWeight: 600,
        paddingLeft: "0.3rem",
        cursor: "pointer",
      },
      firmNameInHeader: {
        position: 'absolute',
        left: '0',
        marginLeft: '40px',
        fontSize: '1.5rem',
        fontWeight:600
      },
    },
    { withTheme: true }
  )
)(SurveySteperWithDialogBox);
